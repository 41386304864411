import i18next from 'i18next';

i18next.addResources('es', 'Signup', {
  registerTitle: 'Registrate',
  signup: 'Continuar',
  iHaveAnAccount: 'Ya tengo una cuenta',
  firstName: 'Nombre',
  lastName: 'Apellido',
  entity: 'Obra social / Financiador',
  phone: 'Teléfono (opcional)',
  email: 'E-mail',
  password: 'Contraseña',
  confirmPassword: 'Confirmar contraseña',
  firstNamePlaceholder: 'Roberto',
  lastNamePlaceholder: 'Perez',
  entityPlaceholder: 'Empresa S.A.',
  emailPlaceholder: 'nombre@empresa.com',
  passwordPlaceholder: 'Ingrese su contraseña',
  confirmPasswordPlaceholder: 'Repita su contraseña',
  continue: 'Continuar',
  alreadySignup: '¿Ya tenés una cuenta? Iniciá sesión',
  'Email has already been taken': 'El mail indicado ya está en uso.',
  'Registration number has already been taken': 'El Nro. de Matricula indicado ya está en uso',
  'Document number has already been taken': 'El Nro. de Documento indicado ya está en uso',
  'First Name is invalid': 'El nombre es inválido.',
  'Last Name is invalid': 'El apellido es inválido.',
  'Entity is invalid': 'Obra social/Financiador es inválido.',
  enrollmentNumber: 'Nro. de Matricula',
  docNumber: 'Nro. de Documento'
});
