import immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';

const initialStateDescription = completeState({ import: { page: [] } });

const reducerDescription = {
  primaryActions: [actions.GET_USERS_LIST]
};

export default createReducer(immutable(initialStateDescription), completeReducer(reducerDescription));
