import i18next from 'i18next';

i18next.addResources('es', 'PatientFlow', {
  treatableCases: 'Casos elegibles por perfil de paciente',
  disclaimer:
    'Los valores aquí presentados representan el valor medio calculado a partir de las mejores estimaciones disponibles de los parámetros epidemiológicos correspondientes. En consecuencia, los valores reales pueden diferir de los valores estimados.',
  showMore: 'Leer más',
  showLess: 'Leer menos',
  source: 'Fuente'
});
