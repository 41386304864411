import { createTypes, completeTypes, withPostSuccess, withSuccess } from 'redux-recompose';

import { Range } from '~components/PopulationWizard/PopulationWizardBasic/components/StepTwoBasic/constants';
import {
  sendDistribution,
  confirmDistribution,
  estimateDistribution,
  getPyramids,
  deletePyramid
} from '~services/PyramidService';

import { TARGETS, PyramidGroup } from './constants';

export const actionsCreator = createTypes(
  completeTypes(
    ['SEND_DISTRIBUTION', 'CONFIRM_DISTRIBUTION', 'ESTIMATE_DISTRIBUTION', 'GET_PYRAMIDS', 'DELETE_PYRAMID'],
    ['UPDATE_BASIC_DISTRIBUTION']
  ),
  ['@@PYRAMID']
);

export interface DistroProps {
  total: number;
  values: any;
  callback: () => void;
  name: string;
  oldName?: string;
}

export const actionCreators = {
  sendDistribution: ({ total, values, callback, name, oldName = name }: DistroProps) => ({
    type: actionsCreator.SEND_DISTRIBUTION,
    target: TARGETS.ESTIMATED_PYRAMID,
    service: sendDistribution,
    payload: { total, groups: values, name, oldName },
    injections: [
      withPostSuccess(() => {
        callback();
      })
    ]
  }),
  confirmDistribution: ({ total, values, callback, name, oldName }: DistroProps) => ({
    type: actionsCreator.CONFIRM_DISTRIBUTION,
    target: TARGETS.PYRAMID,
    service: confirmDistribution,
    payload: { total, groups: values, name, oldName },
    injections: [
      withPostSuccess((dispatch: any) => {
        callback();
        dispatch(actionCreators.saveBasicDistribution(null));
      })
    ]
  }),
  estimateAdvancedDistribution: (groups: Range[], callback: (ranges: PyramidGroup[]) => void) => ({
    type: actionsCreator.ESTIMATE_DISTRIBUTION,
    target: TARGETS.ESTIMATED_DISTRIBUTION,
    service: estimateDistribution,
    payload: groups,
    injections: [
      withPostSuccess((dispatch: any, { data }: any) => {
        callback(data.groups);
        dispatch(actionCreators.saveBasicDistribution(groups));
      })
    ]
  }),
  saveBasicDistribution: (distribution: Range[] | null) => ({
    type: actionsCreator.UPDATE_BASIC_DISTRIBUTION,
    target: TARGETS.BASIC_DISTRIBUTION,
    payload: distribution
  }),
  getPyramids: () => ({
    type: actionsCreator.GET_PYRAMIDS,
    target: TARGETS.PYRAMIDS,
    service: getPyramids
  }),
  deletePyramid: (id: number, callback: () => void) => ({
    type: actionsCreator.DELETE_PYRAMID,
    target: TARGETS.PYRAMIDS,
    service: deletePyramid,
    payload: id,
    injections: [
      withSuccess((dispatch: any) => {
        dispatch({ type: '@@PYRAMID/DELETE_PYRAMID_SUCCESS', payload: id, target: TARGETS.PYRAMIDS });
        dispatch(actionCreators.getPyramids());
        callback();
      })
    ]
  })
};
