import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { RouteProps } from 'react-router';

import { CURRENT_ENV } from '~constants/environments';
import useValidateSession from '~utils/customHooks/useValidateSession';

import { DEFAULT_PRIVATE_ROUTE, DEFAULT_PUBLIC_ROUTE } from './constants';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  isPublicRoute?: boolean;
  isPrivateRoute?: boolean;
}

function AuthenticatedRoute({
  /*
   * TODO Add this if you need it
   * device,
   */
  isPublicRoute,
  isPrivateRoute,
  // initialized,
  component: Comp,
  ...props
}: Props) {
  const session = useValidateSession();
  return (
    <ApmRoute
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(routeProps: any) => {
        /*
         * TODO Add this if you need it
         * if (device.isMobile && !device.adviceSubmitted) {
         *   return <AppDownloader />;
         * }
         */

        if (isPublicRoute && session) {
          /*
           * TODO Add this if you need it
           * if (currentUser && isPublicRoute) {
           * do not allow logged users to access public routes. redirect to app
           */
          return (
            <Redirect
              to={{
                pathname: DEFAULT_PRIVATE_ROUTE[CURRENT_ENV],
                state: { from: props.location }
              }}
            />
          );
        }

        if (isPrivateRoute && !session) {
          // Do not allow unlogged users to access app. redirect to signin
          return (
            <Redirect
              to={{
                pathname: DEFAULT_PUBLIC_ROUTE[CURRENT_ENV].path,
                state: { from: props.location }
              }}
            />
          );
        }
        return <Comp {...routeProps} />;
      }}
    />
  );
}

export default AuthenticatedRoute;
