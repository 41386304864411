import i18next from 'i18next';

i18next.addResources('es', 'AmgevitaNewsLetter', {
  title: 'Forma parte de nuestra comunidad ',
  message:
    'Recibe nuestros boletines mensuales para que no te pierdas nuestros eventos y nuevas investigaciones en curso.',
  anchor: 'Quiero suscribirme'
});

i18next.addResources('es', 'KanjintiNewsLetter', {
  title: '¿Querés ser contactado por parte de Amgen?',
  message: 'Si querés ser contactado por un representante o recibir más información suscribite.',
  anchor: 'Quiero suscribirme'
});
