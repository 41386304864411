import { createTypes, completeTypes, withPostSuccess, withPostFailure, withSuccess } from 'redux-recompose';
import { ApiResponse } from 'apisauce';

import { LoginProps } from '~screens/Authentication/screens/Login/loginModel';
import {
  loginUser,
  getUserInfo,
  restorePopulation,
  getUserPopulation,
  recoverPassword,
  setPassword,
  logoutUser
} from 'services/LoginService';
import api from 'config/api';

import {
  TARGETS,
  TOKEN,
  UID,
  CLIENT,
  USER_ID,
  User,
  HTTP_STATUS_CODES,
  HTTP_RESPONSES,
  ResponseFailData,
  ResponseSuccessData,
  ResponsePostSuccess
} from './constants';

export const actions = createTypes(
  completeTypes(
    [
      'LOGIN_USER',
      'LOGOUT_USER',
      'GET_USER',
      'RESTORE_POPULATION',
      'GET_USER_POPULATION',
      'UPDATE_POPULATION',
      'RECOVER_PASSWORD',
      'SET_PASSWORD'
    ],
    ['CLEAR_USER', 'CLEAR_USER_POPULATION', 'SET_USER_POPULATION']
  ),
  ['@@USER']
);

const actionCreators = {
  loginUser: (user: LoginProps) => ({
    type: actions.LOGIN_USER,
    target: TARGETS.USER,
    service: loginUser,
    payload: { user },
    successSelector: (response: ResponseSuccessData): User => {
      const { [TOKEN]: token, [UID]: uid, [CLIENT]: client } = response.headers;
      api.setHeaders({ [TOKEN]: token, [UID]: uid, [CLIENT]: client });
      return {
        userId: response.data.id,
        token: response.headers[TOKEN],
        uid: response.headers[UID],
        mail: response.headers[CLIENT],
        onboarding: response.data.signInCount === 1
      };
    },
    failureSelector: (response: ApiResponse<ResponseFailData>) => {
      const { data, status } = response;
      if (data?.errors?.[0]?.includes('has not been approved by an administrator yet.')) {
        return HTTP_RESPONSES.PENDING;
      }
      if (status === HTTP_STATUS_CODES.TOO_MANY_REQUESTS) {
        return HTTP_RESPONSES.TOO_MANY_REQUESTS;
      }

      return HTTP_RESPONSES.CLIENT_ERROR;
    },
    injections: [
      withPostSuccess((dispatch: any, response: ResponsePostSuccess) => {
        const { [TOKEN]: token, [UID]: uid, [CLIENT]: client } = response.headers;
        const userId = response.data.id;
        localStorage.setItem(TOKEN, token);
        localStorage.setItem(UID, uid);
        localStorage.setItem(CLIENT, client);
        localStorage.setItem(USER_ID, userId);
      })
    ]
  }),
  getUserInfo: (id: number) => ({
    type: actions.GET_USER,
    target: TARGETS.USER_INFO,
    service: getUserInfo,
    payload: id,
    successSelector: (response: ApiResponse<User>) => response.data
  }),
  getUserPopulation: () => ({
    type: actions.GET_USER_POPULATION,
    target: TARGETS.USER_POPULATION,
    service: getUserPopulation,
    successSelector: (response: ApiResponse<User>) => response.data,
    injections: [
      withPostFailure((dispatch: Function) => {
        dispatch({
          type: actions.CLEAR_USER_POPULATION,
          target: TARGETS.USER_POPULATION
        });
      })
    ]
  }),
  clearUser: () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(UID);
    localStorage.removeItem(CLIENT);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem('importedUser');
    return {
      type: actions.CLEAR_USER,
      target: TARGETS.USER
    };
  },
  restorePopulation: () => ({
    type: actions.RESTORE_POPULATION,
    target: TARGETS.USER_POPULATION,
    service: restorePopulation
  }),
  updatePopulation: (amount: number, name: string, oldName?: string) => ({
    type: actions.UPDATE_POPULATION,
    target: TARGETS.USER_POPULATION,
    payload: { amount, name, oldName }
  }),
  recoverPassword: (email: string, callback: () => void) => ({
    type: actions.RECOVER_PASSWORD,
    target: TARGETS.RECOVER_PASSWORD,
    service: recoverPassword,
    payload: email,
    injections: [withPostSuccess(() => callback())]
  }),
  setPassword: (password: string, token: string, callback: () => void) => ({
    type: actions.SET_PASSWORD,
    target: TARGETS.SET_PASSWORD,
    service: setPassword,
    payload: { password, token },
    injections: [withPostSuccess(() => callback())]
  }),
  logoutUser: () => {
    const token = localStorage.getItem(TOKEN)!;
    const uid = localStorage.getItem(UID)!;
    const client = localStorage.getItem(CLIENT)!;
    return {
      type: actions.LOGOUT_USER,
      target: TARGETS.USER,
      service: logoutUser,
      payload: {
        'access-token': token,
        uid,
        client
      },
      injections: [
        withSuccess((dispatch: Function) => {
          dispatch(actionCreators.clearUser());
        })
      ]
    };
  },
  setPopulationAmount: (pyramid: any) => ({
    type: actions.SET_USER_POPULATION,
    target: TARGETS.USER_POPULATION,
    payload: pyramid
  })
};

export default actionCreators;
