import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import {
  getPathologies,
  getPathologyDetail,
  getPathologyStatics,
  editPatientFlow,
  getPathologyPatientFlows,
  editScenario
} from 'services/Pathologies';

import { TARGETS, PatientFlow, PatientFlowElement } from './constants';

export const actions = createTypes(
  completeTypes([
    'GET_PATHOLOGIES',
    'CLEAR_PATHOLOGIES',
    'GET_DETAIL',
    'GET_STATICS',
    'GET_PATIENT_FLOWS',
    'EDIT_PATIENT_FLOW',
    'EDIT_SCENARIO'
  ]),
  ['@@PATHOLOGIES']
);

export const actionCreators = {
  getPathologies: (page = 1, user?: number, pyramidId?: number) => ({
    type: actions.GET_PATHOLOGIES,
    target: TARGETS.PATHOLOGIES,
    service: getPathologies,
    payload: { page, user, pyramidId }
  }),
  clearPathologies: () => ({
    type: actions.CLEAR_PATHOLOGIES,
    target: TARGETS.PATHOLOGIES
  }),
  getPathologyDetail: (id: number, pyramidId?: number, user?: number) => ({
    type: actions.GET_DETAIL,
    target: TARGETS.PATHOLOGY,
    service: getPathologyDetail,
    payload: { id, pyramidId, user }
  }),
  getPathologyStatics: (id: number, pyramidId?: number, user?: number) => ({
    type: actions.GET_STATICS,
    target: TARGETS.PATHOLOGY_STATICS,
    service: getPathologyStatics,
    payload: { id, pyramidId, user }
  }),
  getPathologyPatientFlows: (id: number, pyramidId?: number, user?: number) => ({
    type: actions.GET_PATIENT_FLOWS,
    target: TARGETS.PATHOLOGY_PATIENT_FLOWS,
    service: getPathologyPatientFlows,
    payload: { id, pyramidId, user },
    successSelector: (response: any) =>
      response.data.sort((a: PatientFlowElement, b: PatientFlowElement) => a.initialAge - b.initialAge)
  }),
  editPatientFlow: (patientFlowId: number, patientFlows: PatientFlow[], callback: () => void) => ({
    type: actions.EDIT_PATIENT_FLOW,
    target: TARGETS.EDITED_PATIENT_FLOW,
    service: editPatientFlow,
    payload: { patientFlows, patientFlowId },
    injections: [
      withPostSuccess(() => {
        callback();
      })
    ]
  }),
  editScenario: (patientFlowId: number, scenarioId: number) => ({
    type: actions.EDIT_SCENARIO,
    target: TARGETS.PATHOLOGY_PATIENT_FLOWS,
    service: editScenario,
    payload: { patientFlowId, scenarioId }
  })
};
