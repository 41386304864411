import { createTypes } from 'redux-recompose';

import { importUsers } from 'services/ImportUsers';

import { TARGETS } from './constants';

export const actions = createTypes(['GET_USERS_LIST'], ['@@IMPORT_USERS']);

export default {
  getUsersList: (page: number) => ({
    type: actions.GET_USERS_LIST,
    target: TARGETS.IMPORT,
    service: importUsers,
    payload: page
  })
};
