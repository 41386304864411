import i18next from 'i18next';

i18next.addResources('es', 'SubscriptionForm', {
  title: 'Quiero recibir más información',
  firstName: 'Nombre',
  lastName: 'Apellido',
  docNumber: 'Número de documento',
  enrollmentNumber: 'Número de matrícula',
  email: 'E-mail',
  send: 'Enviar',
  error: 'Usted ya se encuentra suscripto',
  info: 'Quiero recibir información sobre {{landingName}}.'
});
