import i18next from 'i18next';

i18next.addResources('es', 'Pathologies', {
  pathologies: 'Patologías',
  pathologiesDescription:
    'Podés visualizar los casos de las patologias por separado o seleccionarlos para sumarlos entre sí.',
  potentialCases: 'Casos potenciales seleccionados',
  treatableCases: 'Casos elegibles seleccionados',
  download: 'Descargar reportes',
  warningTitle: '¡Aún no cargaste tus afiliados!',
  warningInfo:
    'Para visualizar los reportes, ingresa tu cantidad de afiliados y cómo se distribuyen según rangos de edad.',
  warningNextLabel: 'Cargar afiliados'
});
