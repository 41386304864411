import i18next from 'i18next';

i18next.addResources('es', 'analyticalGraph', {
  title: 'Similaridad con el PR en todos los atributos críticos de calidad evaluados',
  text: 'Caracterización analítica y funcional',
  indicator: '+100',
  indicatorDescription: 'Ensayos de caracterización analítica y de actividad funcional'
});

i18next.addResources('es', 'graphToxicokineticParameters', {
  title: 'Toxicocinética similar al PR',
  text: 'Estudio de toxicocinética',
  indicator: '1',
  indicatorDescription: 'Estudios Pre-clínicos'
});

i18next.addResources('es', 'immunogenicityProfileGraph', {
  title: 'Farmacocinética, farmacodinamia e inmunogenicidad similar al PR',
  text: 'Estudio de fase I',
  indicator: '1',
  indicatorDescription: 'Estudio en farmacología clínica'
});

i18next.addResources('es', 'rheumatoidEfficacyAndSafety', {
  title:
    'Eficacia y Seguridad similares al PR demostrada en Estudios FIII en artritis reumatoidea y psoriasis en placa',
  text: 'Estudios clínicos de Amgevita',
  indicator: '2',
  indicatorDescription: 'Estudios clínicos'
});
