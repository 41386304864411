import React, { useEffect, useState, useContext } from 'react';

interface ValueType {
  width: number;
  height: number;
}

const defaultValue: ValueType = {
  width: 0,
  height: 0
};

const ViewportContext = React.createContext(defaultValue);
export const useViewport = () => useContext(ViewportContext);

export default function ViewportProvider({ children }: { children: any }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
}
