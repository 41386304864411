import i18next from 'i18next';

i18next.addResources('es', 'Pathology', {
  fullReport: 'Ver reporte completo',
  incidence: 'Incidencia',
  prevalence: 'Prevalencia',
  potentialCases: 'Casos Potenciales',
  treatableCases: 'Casos Elegibles',
  previewMode: 'Modo previsualización'
});
