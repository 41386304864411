import i18next from 'i18next';

i18next.addResources('es', 'Sustainability', {
  title: 'Acceso y Sustentabilidad',
  text:
    '{{appName}} es un biosimilar de alta calidad con evidencia clínica robusta. Su elección garantiza no sólo maximizar valor sino también ahorros importantes a corto y largo plazo.'
});

i18next.addResources('es', 'SustainabilityMarket', {
  title: 'Mercado sostenible y competitivo',
  text:
    'Su presencia en el mercado favorece la competencia, lo cual contribuye a la sustentabilidad del sistema de salud a largo plazo.'
});

i18next.addResources('es', 'SustainabilityBudget', {
  title: 'Libera presupuesto',
  text:
    'La reducción del gasto permite reasignar presupuesto para tratar a más pacientes y expandir el acceso, inclusive en otras áreas terapeuticas o en distintas patologías.'
});

i18next.addResources('es', 'SustainabilityInnovation', {
  title: 'Genera innovación',
  text:
    'Los ahorros asociados a su uso pueden invertirse en tratamientos innovadores fomentando así la investigación y desarrollo de más soluciones.'
});
