import i18next from 'i18next';

i18next.addResources('es', 'adminScenario', {
  title: 'Gestión de escenarios',
  description: 'Seleccioná el escenario que quieras aplicar a la patología',
  selectNone: 'Seleccionar ninguno',
  applyButton: 'Aplicar escenario',
  pagination: 'Mostrando {{firstItem}}-{{lastItem}} de {{totalItems}}',
  selectTitle: 'Escenarios',
  editScenario: 'Editar escenario',
  deleteScenario: 'Eliminar escenario',
  save: 'Guardar escenario',
  cancel: 'Cancelar edición',
  goForward: 'Siguiente página',
  goBackward: 'Página anterior',
  loading: 'Cargando escenarios',
  deleteTitle: '¿Seguro querés eliminar este escenario?',
  deleteText:
    'Una vez eliminado el escenario perderás toda la información del Pacient flow cargada en el mismo.',
  confirmDelete: 'Eliminar'
});
