import { RouteComponentProps } from 'react-router-dom';
import { lazy } from 'react';

import Routes from '~constants/routes';

import { LOGIN_LANDINGS, ENVIRONMENTS } from '../../../../constants/environments';

const Authentication = lazy(() => import('~screens/Authentication'));
const Validation = lazy(() => import('~screens/Validation'));

interface Route {
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const AUTHENTICATION_ROOT: Route = {
  path: Routes.AUTHENTICATION,
  component: Authentication
};

const VALIDATION_ROOT: Route = {
  path: Routes.VALIDATION,
  component: Validation
};

export const DEFAULT_PUBLIC_ROUTE = ((useValidation = LOGIN_LANDINGS === 'true') => ({
  [ENVIRONMENTS.AMGEVITA as string]: useValidation ? AUTHENTICATION_ROOT : VALIDATION_ROOT,
  [ENVIRONMENTS.KANJINTI as string]: useValidation ? AUTHENTICATION_ROOT : VALIDATION_ROOT,
  [ENVIRONMENTS.EPIDATA as string]: AUTHENTICATION_ROOT
}))();

export const DEFAULT_PRIVATE_ROUTE = {
  [ENVIRONMENTS.EPIDATA]: Routes.HOME,
  [ENVIRONMENTS.KANJINTI]: Routes.LANDING_HOME,
  [ENVIRONMENTS.AMGEVITA]: Routes.LANDING_HOME
};
