import i18next from 'i18next';

i18next.addResources('es', 'Amgevita', {
  reference1:
    '(1) Papp K, Bachelez H, Costanzo A, Foley P, Gooderham M, Kaur P, Narbutt J, Philipp S, Spelman L, Weglowska J, Zhang N, Strober B. Clinical similarity of biosimilar ABP 501 to adalimumab in the treatment of patients with moderate to severe plaque psoriasis: a randomized, double-blind, multicenter, Phase III study. Journal of the American Academy of Dermatology. 2017;76(6):1093–1102.',
  reference2:
    '(2) Cohen S, Genovese MC, et al. Efficacy and safety of the biosimilar ABP 501 compared with adalimumab in patients with moderate to severe rheumatoid arthritis: a randomised, double-blind, Phase III equivalence study. Annals of the Rheumatic Diseases. 2017;76(10):1679–1687.',
  reference3:
    '(3) Amgen Data on File. Octobre 2019 – Estimado basado en el total de unidades vendidas hasta Octobre 2019.',
  reference4: '(4) Liu J, et al. BioDrugs 2016;30:321–38.',
  reference5: '(5) Velayudhan J, et al. BioDrugs 2016;30:339–351.',
  reference6:
    '(6) Amgen Inc. Background Information for AAC 12 July 2016. Biologics License Application for ABP 501.',
  reference7: '(7) Kaur P, et al. Ann Rheum Dis 2017;76:526–33.'
});

i18next.addResources('es', 'Kanjinti', {
  reference1: '(1) Von Minckwitz G, et al. Lancet Oncol 2018;19:987–98 & supplemental appendix.',
  reference2: '(2) Hanes V, et al. Cancer Chemother Pharmacol 2017;79:881–8.',
  reference3: '(3) Hanes V, et al. EBCC 2016, Abstract 436 and poster.',
  reference4: '(4) Hutterer K, et al. WCBP Symposium 2017, Abstract P-207-TH and poster; Amgen, Data on File'
});
