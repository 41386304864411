import { Pyramid } from '~redux/Pyramid/constants';
import api from 'config/api';

export interface LoginState<TpopulationAmount = PopulationAmount> {
  user: User;
  userLoading: boolean;
  userError: string[];
  userInfoLoading: boolean;
  userInfoError: string[];
  status: string;
  userInfo: UserInfo;
  populationAmount: TpopulationAmount;
  populationAmountLoading: boolean;
  populationAmountError: boolean;
  recoverPasswordLoading: boolean;
  recoverPasswordSuccess: boolean;
  recoverPasswordError: boolean;
  setPasswordLoading: boolean;
  setPasswordSuccess: boolean;
  setPasswordError: boolean;
  merge: (object: {}) => LoginState;
}
export interface StatusState {
  confirmationStatus: string;
}

export enum userType {
  payer = 'payer',
  infield = 'infield'
}

export interface PopulationAmount extends Pyramid {
  totalPopulation: number;
  name: string;
  oldName?: string;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  entity: string;
  userType: userType;
}

export interface User {
  userId: number;
  token: string;
  uid: string;
  mail: string;
  onboarding: boolean;
}

export interface LogoutHeaders {
  'access-token': string;
  uid: string;
  client: string;
}

export interface ResponseFailData {
  success: boolean;
  errors: string[];
}

export interface ResponseSuccessData {
  headers: LogoutHeaders;
  data: {
    id: number;
    signInCount: number;
  };
}

export interface ResponsePostSuccess {
  headers: LogoutHeaders;
  data: {
    id: string;
  };
}

export const TARGETS = {
  USER: 'user',
  STATUS: 'status',
  USER_INFO: 'userInfo',
  USER_POPULATION: 'populationAmount',
  TOTAL_POPULATION: 'totalPopulation',
  RECOVER_PASSWORD: 'recoverPassword',
  SET_PASSWORD: 'setPassword'
};
export const TOKEN = 'access-token';
export const APPROVED = 'approved';
export const UID = 'uid';
export const CLIENT = 'client';
export const USER_ID = 'id';
export const RESET_PASSWORD_TOKEN = 'reset-password-token';

export const getDefaultState = () => {
  const isLogged = localStorage.getItem(TOKEN);

  if (isLogged) {
    const token = localStorage.getItem(TOKEN)!;
    const uid = localStorage.getItem(UID)!;
    const client = localStorage.getItem(CLIENT)!;
    api.setHeaders({ [TOKEN]: token, [UID]: uid, [CLIENT]: client });
  }
  return {
    user: {
      userId: localStorage.getItem(USER_ID),
      token: isLogged,
      uid: localStorage.getItem(UID),
      mail: localStorage.getItem(CLIENT)
    },
    populationAmount: {
      groups: [],
      totalPopulation: null,
      name: ''
    }
  };
};

export const HTTP_STATUS_CODES = {
  TOO_MANY_REQUESTS: 429
};

export const HTTP_RESPONSES = {
  CLIENT_ERROR: 'CLIENT_ERROR',
  TOO_MANY_REQUESTS: 'tooManyRequests',
  PENDING: 'pending'
};
