import i18next from 'i18next';

i18next.addResources('es', 'Section1', {
  name: 'Tipos de Estudios Clínicos en Productos Biológicos y Biosimilares - Dr Enrique Soriano'
});

i18next.addResources('es', 'Section2Subsection1', {
  name: 'Estudio FIII en Artritis Reumatoidea (AR) moderada a severa',
  subtitle: 'Diseño del Estudio (AR)',
  references:
    '* Población de estudio. Adultos con AR de moderada a severa que habían tenido respuesta inadecuada al metotrexato.\n† Los sujetos fueron asignados al azar en proporción 1:1 a recibir AMGEVITA o adalimumab\n‡ La equivalencia clínica se evaluó comparando el intervalo de confianza (IC) de RR del 90% con un margen equivalente de (0,738; 1,355)\n^Las variables secundarias más importantes fueron el RR de ACR70, el cambio en el puntaje DAS28 CRP de referencia, la seguridad y la inmunogenicidad.\nNCT01970475 . NCT02114931'
});

i18next.addResources('es', 'Section2Subsection2', {
  name: 'Criterios de Inclusión y Exclusion Criterios de Valoración (AR)'
});

i18next.addResources('es', 'Section2Subsection2Criteria1', {
  subtitle: 'Criterios de Inclusión',
  leftCriteria1: 'Criterios de inclusión',
  rightCriteria1: 'Criterios de exclusión',
  leftCriteria1Element1: 'Edad ≥18 y ≤75 años',
  leftCriteria1Element2: 'PsO estable de moderada a severa durante al menos 6 meses',
  leftCriteria1Element3: 'BSA ≥10%, PASI ≥12 y sPGA ≥3 en la selección y en la evaluación basal',
  leftCriteria1Element4: 'Sin antecedentes conocidos de tuberculosis activa',
  leftCriteria1Element5:
    'Fracaso previo, respuesta inadecuada, intolerancia o contraindicación a por lo menos un tratamiento antipsoriásico sistémico convencional (por ej., metotrexato, ciclosporina, psoraleno más luz UV A)',
  rightCriteria1Element1: 'Intervención quirúrgica planeada durante el estudio',
  rightCriteria1Element2: 'Infección activa o antecedentes de infecciones',
  rightCriteria1Element3:
    'Insuficiencia cardíaca de moderada a severa (clase III/IV según la New York Heart Association)',
  rightCriteria1Element4:
    'Antecedentes de hipersensibilidad al principio activo o a cualquiera de los excipientes de adalimumab o de ABP 501',
  rightCriteria1Element5:
    'Todo trastorno médico concurrente que, a criterio del investigador, pudiera causar que este estudio fuera perjudicial para el sujeto',
  rightCriteria1Element6: 'Uso previo de adalimumab o de un biosimilar de adalimumab',
  rightCriteria1Element7: 'Uso previo de 2 o más biológicos para el tratamiento de la PsO'
});

i18next.addResources('es', 'Section2Subsection2Criteria2', {
  subtitle: 'Criterios de Valoración',
  leftCriteria1: 'Criterio de valoración primario 1',
  leftCriteria2: 'Criterios de valoración secundarios 1',
  leftCriteria3: 'Criterios de seguridad 1',
  rightCriteria1: 'Criterios de valoración exploratorios 1,2',
  leftCriteria1Element1: 'ACR20 en la Semana 24',
  leftCriteria2Element1: 'DAS28-CRP en las Semanas 2, 4, 8, 12, 18 y 24',
  leftCriteria2Element2: 'Respuestas ACR20 en las Semanas 2 y 8',
  leftCriteria2Element3: 'Respuestas ACR50 y ACR70 en la Semana 24 ',
  leftCriteria3Element1: 'EAET y AES',
  leftCriteria3Element2:
    'Cambios clínicamente significativos en los valores de laboratorio y los signos vitales',
  leftCriteria3Element3: 'Incidencia de anticuerpos antifármaco',
  rightCriteria1Element1:
    'Percepción de dolor en el lugar de la inyección en base a las clasificaciones de ABP 501 del sujeto en comparación con adalimumab',
  rightCriteria1Element2:
    'Concentración sérica valle de ABP 501 en comparación con adalimumab en las Semanas 2, 4, 12, 24 y fin del estudio',
  references:
    'RR, razón de riesgos.\n1. Cohen S, et al. Ann Rheum Dis 2017;76:1679–87. Supplementary material – protocol; \n2. Kaur P, et al. AMCP 2017, Poster M2.'
});

i18next.addResources('es', 'Section2Subsection3', {
  subtitle: 'Características basales de la enfermedad (AR)',
  references:
    '*AMGEVITA®, n=264; adalimumab, n=261; total, n=525; †ABP 501, n=263; adalimumab, n=261; total, n=524; ‡En la selección. HAQ-DI, Cuestionario de Evaluación de Salud –Índice de Discapacidad; NSAID, antiinflamatorios no esteroides;\nSD, desviación estándar; VAS, escala análoga visual.'
});

i18next.addResources('es', 'Section2Subsection4', {
  subtitle: 'Resultados del Criterio de Valoración Primaria (AR)',
  references:
    '* La razón de riesgos de ACR20 y su intervalo de confianza se calcularon usando un modelo estadístico ajustado en función de las covariables\n†Serie de análisis completa con última observación proyectada.\nCohen S, et al. Ann Rheum Dis 2017;76:1679–87.'
});

i18next.addResources('es', 'Section2Subsection5', {
  name: 'Eficacia comprobada y similar durante 2 años de análisis (AR). '
});

i18next.addResources('es', 'Section2Subsection5Image1', {
  subtitle:
    'Eficacia comprobada similar luego de realizar la transición del producto de referencia a Amgevita',
  references: 'Intervalo de confianza 90%. No hay diferencia significativa'
});

i18next.addResources('es', 'Section2Subsection5Image2', {
  subtitle: 'Con Amgevita se observó una disminución en DAS28-PCR a las semanas 24 y 72',
  references: 'Intervalo de confianza 90%. No hay diferencia significativa'
});

i18next.addResources('es', 'Section2Subsection6', {
  name: 'Seguridad e Inmunogenicidad (AR)'
});

i18next.addResources('es', 'Section2Subsection6Image1', {
  subtitle: 'Perfil de seguridad similar al producto de referencia',
  references: 'EA: Efectos adversos'
});

i18next.addResources('es', 'Section2Subsection6Image2', {
  subtitle: 'Inmunogenicidad',
  references:
    '* Conjunto de análisis de anticuerpos antidrogas. La sensibilidad del ensayo electroquimioluminiscente (ECL) para ADA fue de aproximadamente 0,02 ug/ml en presencia de 25 ug/ml de fármaco.\nCohen S y col. Ann Rheum Dis 2017; 76: 1679 87 y material complementario 1; Chow V y col. ECCO 2018, póster P404; Mytych DT, y col. ECCO 2018, póster P751; Krishnan E, y col. EULAR 2018, póster THU0198.'
});

i18next.addResources('es', 'Section2Subsection6Image3', {
  subtitle: 'Incidencia total de desarrollo de anticuerpos en el estudio OLE'
});

i18next.addResources('es', 'Section3Subsection1', {
  name: 'Estudio FII en Psoariasis en Placa (PSO) moderada a severa',
  subtitle: 'Diseño del Estudio (PSO)',
  references: '* SC: Subcutáneo'
});

i18next.addResources('es', 'Section3Subsection2', {
  name: 'Criterios de Inclusión y Exclusion Criterios de Valoración (PSO)'
});

i18next.addResources('es', 'Section3Subsection2Criteria1', {
  subtitle: 'Criterios de Inclusión y Exclusión',
  leftCriteria1: 'Criterios de inclusión',
  rightCriteria1: 'Criterios de exclusión',
  leftCriteria1Element1: 'Edad ≥18 y ≤75 años',
  leftCriteria1Element2: 'PsO estable de moderada a severa durante al menos 6 meses',
  leftCriteria1Element3: 'BSA ≥10%, PASI ≥12 y sPGA ≥3 en la selección y en la evaluación basal',
  leftCriteria1Element4: 'Sin antecedentes conocidos de tuberculosis activa',
  leftCriteria1Element5:
    'Fracaso previo, respuesta inadecuada, intolerancia o contraindicación a por lo menos un tratamiento antipsoriásico sistémico convencional (por ej., metotrexato, ciclosporina, psoraleno más luz UV A)',
  rightCriteria1Element1: 'Intervención quirúrgica planeada durante el estudio',
  rightCriteria1Element2: 'Infección activa o antecedentes de infecciones',
  rightCriteria1Element3:
    'Insuficiencia cardíaca de moderada a severa (clase III/IV según la New York Heart Association)',
  rightCriteria1Element4:
    'Antecedentes de hipersensibilidad al principio activo o a cualquiera de los excipientes de adalimumab o de ABP 501',
  rightCriteria1Element5:
    'Todo trastorno médico concurrente que, a criterio del investigador, pudiera causar que este estudio fuera perjudicial para el sujeto',
  rightCriteria1Element6: 'Uso previo de adalimumab o de un biosimilar de adalimumab',
  rightCriteria1Element7: 'Uso previo de 2 o más biológicos para el tratamiento de la PsO',
  references:
    'BSA, área de superficie corporal; PsO, psoriasis en placas; sPGA, Evaluación Global del Médico estática; UV, ultravioleta.\nPapp K, et al. J Am Acad Dermatol 2017;76:1093–102; Papp K, et al. EADV 2015, Poster 1736.'
});

i18next.addResources('es', 'Section3Subsection2Criteria2', {
  subtitle: 'Criterios de Valoración',
  leftCriteria1: 'Criterios de inclusión',
  rightCriteria1: 'Criterios de exclusión',
  leftCriteria1Element1:
    'Evaluar la eficacia, seguridad e inmunogenicidad entre ABP 501 y el PR adalimumab en adultos con psoriasis en placas de moderada a severa',
  rightCriteria1Element1: 'Porcentaje de mejoría en el PASI desde la evaluación basal hasta la Semana 16',
  references:
    'RR, razón de riesgos.\n1. Cohen S, et al. Ann Rheum Dis 2017;76:1679–87. Supplementary material – protocol; \n2. Kaur P, et al. AMCP 2017, Poster M2.'
});

i18next.addResources('es', 'Section3Subsection3', {
  name: 'Características basales de la población (PSO)',
  references: 'EA: Efectos adversos'
});

i18next.addResources('es', 'Section3Subsection4', {
  name: 'Resultados del Criterio de Valoración Primario (PSO)',
  references:
    '*Serie de análisis completa, última observación proyectada; †La diferencia media y el IC se calcularon con un modelo estadístico ajustado en función de las covariables.\nIC, intervalo de confianza.\nPapp K, et al. J Am Acad Dermatol 2017;76:1093–102; Amgen Inc. Background Information for AAC 12 July 2016. Biologics License Application for ABP 501.'
});

i18next.addResources('es', 'Section3Subsection5', {
  name: 'Eficacia'
});

i18next.addResources('es', 'Section3Subsection5Image1', {
  subtitle: 'La mejoría del PASI* a la semana 16 fue similar entre Amgevita y el producto de referencia ',
  references:
    'La diferencia de los promedios de la mejoría del PASI de Amgevita vs producto de referencia es de -2,18% con un IC 95% que está dentro del rango de bioequilavencia.\n* PASI: Psoriasis Area Severity Index'
});

i18next.addResources('es', 'Section3Subsection5Image2', {
  subtitle: 'Mejoría sostenida del PASI* a la semana 52',
  references: '*PASI: Psoriasis Area Severity Index'
});

i18next.addResources('es', 'Section3Subsection6', {
  name: 'Seguridad e Inmunogenicidad (AR)'
});

i18next.addResources('es', 'Section3Subsection6Image1', {
  references: 'EA: Efectos adversos'
});

i18next.addResources('es', 'Section3Subsection6Image2', {
  subtitle: 'Inmunogenicidad',
  references:
    'Re-aleatorizado: AMGEVITA®/AMGEVITA® n = 152; adalimumab RP / adalimumab RP n = 79; adalimumab RP / AMGEVITA® n = 77.\n* Los pacientes con Adalimumab RP / AMGEVITA® en el brazo de la semana 16 aún no habían recibido AMGEVITA®.\n† 347 pacientes fueron incluidos en el análisis completo establecido hasta la semana 16; 42 pacientes no fueron aleatorizados nuevamente AMGEVITA®, n = 23; adalimumab, n = 19\nMytych DT, y col. ECCO 2018, Póster P655; Papp K y col. Br J Dermatol 2017; 177: 1562–74'
});
