import { create } from "apisauce";


const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = create({
  /*
   * TODO Add this if you need it
   * baseURL: process.env.API_BASE_URL,
   */
  baseURL,
  timeout: 15000
});

export default api;
