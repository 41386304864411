import i18next from 'i18next';

i18next.addResources('es', 'InfieldActions', {
  titleExplanation: 'Obtené una previsibilidad más exacta de tus afiliados',
  seePyramid: 'Ver Pirámide',
  affiliates: 'Afiliados',
  update: 'Crear Simulación',
  simulate: 'Simular Población',
  import: 'Importar Afiliados',
  restore: 'Reestablecer Población',
  description: 'El escenario actual está basado en los datos de la población Argentina.',
  cancel: 'Cancelar',
  continue: 'Continuar',
  alertTitle: '¿Querés importar una población?',
  alertDescription: 'Vas a perder los datos que cargaste para la simulación.',
  populationNotLoaded: 'Población sin cargar',
  importCSV: 'Importar CSV'
});
