import React, { useEffect, useState, useContext } from 'react';

import { getScrollPosition } from '~utils/getScrollPosition';

type ValueType = {
  scroll: {
    prevValue: number;
    value: number;
    direction: 'down' | 'up';
  };
};

const defaultValue: ValueType = {
  scroll: {
    prevValue: 0,
    value: 0,
    direction: 'down'
  }
};

const WindowEventsContext = React.createContext(defaultValue);
export const useWindowEvents = () => useContext(WindowEventsContext);

export default function ScrollEventProvider({ children }: { children: any }) {
  const [value, setValue] = useState(defaultValue);

  const onScroll = () => {
    const { y } = getScrollPosition();
    setValue(prevState => {
      const { scroll } = prevState;
      const { value: prevValue } = scroll;

      const direction = prevValue > y ? 'up' : 'down';

      return { ...prevState, scroll: { prevValue, value: y, direction } };
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return <WindowEventsContext.Provider value={value}>{children}</WindowEventsContext.Provider>;
}
