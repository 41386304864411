import immutable from 'seamless-immutable';
import { createReducer } from 'redux-recompose';

import { actions } from './actions';
import { ModalState, ModalIds } from './constants';

const initialStateDescription = {
  IntroductionModal: true,
  data: undefined
};

const reducerDescription = {
  [actions.OPEN_MODAL]: (state: ModalState, { payload }: { payload: { modalId: ModalIds; data?: any } }) => ({
    ...state,
    [payload.modalId]: true,
    data: payload.data
  }),
  [actions.CLOSE_MODAL]: (state: ModalState, { payload }: { payload: { modalId: ModalIds } }) => ({
    ...state,
    [payload.modalId]: false,
    data: undefined
  })
};

const reducer = createReducer(immutable(initialStateDescription), reducerDescription);

export default reducer;
