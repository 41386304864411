import i18next from 'i18next';

i18next.addResources('es', 'Explanation', {
  title: 'Misma dosis por vial que el producto de referencia',
  subtitle: 'Cada vial de KANJINTI contiene 420 mg extraíbles de trastuzumab.',
  description:
    'Para ello, cada uno de los viales es llenado con 440 mg. La diferencia de 20 mg está dada por el volumen excedente, lo que garantiza que se pueda retirar hasta 420 mg de cada uno de los viales.',
  seeMore: 'Ver Informacion de Seguridad Importante y adicional',
  fill: 'Reconstitución',
  fillDescription: '440 mg de trastuzumab',
  extraction: 'Extracción',
  extractionDescription: 'Extracción de medicación',
  infusion: 'Infusión',
  infusionDescription: 'Hasta 420 mg de trastuzumab',
  injection: 'Cantidad no extraíble',
  injectionDescription: '20 mg de trastuzumab (5% del vial)'
});
