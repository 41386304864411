import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import { signup, checkUser } from '~services/Signup';
import { UserForm } from '~screens/Authentication/screens/Signup/types';
import { TARGETS, User } from '~redux/Signup/constants';

export const actions = createTypes(completeTypes(['SIGNUP', 'CHECK_USER']), ['@@SIGNUP']);

export const actionCreators = {
  signup: (user: User, onSuccess: () => void) => ({
    type: actions.SIGNUP,
    target: TARGETS.USER,
    service: signup,
    payload: user,
    injections: [withPostSuccess(onSuccess)],
    failureSelector: ({ data, problem }: any) => (data ? data.errors : [problem])
  }),
  checkUser: (user: UserForm, onSuccess: () => void) => ({
    type: actions.CHECK_USER,
    target: TARGETS.TEMP_USER,
    service: checkUser,
    payload: user,
    injections: [withPostSuccess(onSuccess)],
    successSelector: () => user,
    failureSelector: ({ data, problem }: any) => (data ? data.errors : [problem])
  })
};
