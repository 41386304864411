import immutable from 'seamless-immutable';
import { createReducer, onSuccess } from 'redux-recompose';

import { actions } from './actions';

const initialStateDescription = localStorage.getItem('importedUser')
  ? { user: JSON.parse(localStorage.getItem('importedUser')!), totalPopulation: null }
  : {};

const reducerDescription = {
  [actions.SET_USER]: onSuccess(),
  [actions.CLEAR_USER]: onSuccess()
};

export default createReducer(immutable(initialStateDescription), reducerDescription);
