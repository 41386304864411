import i18next from 'i18next';

i18next.addResources('es', 'TermsAndConditions', {
  termsAndConditions: 'Términos y condiciones',
  dismiss: 'Al registrarte, estás aceptando nuestros términos y políticas de privacidad',
  cancel: 'Cancelar',
  acceptAndContinue: 'Aceptar y Continuar',
  date: 'Estos Términos y Condiciones fueron actualizados por última vez el 27 de abril de 2020.'
});

i18next.addResources('es', 'TermsAndConditionsOfUse', {
  title: 'TÉRMINOS Y CONDICIONES DE USO',
  text1:
    'Esta sección establece los términos y condiciones (en adelante, los “Términos y Condiciones”) para el uso de los contenidos y servicios (en adelante, los “Servicios”) del sitio web especificado en la cláusula 1 (en adelante, el “Sitio Web”). El Sitio Web y los Servicios son prestados por la empresa Amgen Biotecnología de Argentina S.R.L. (en adelante, “Amgen”).',
  text2:
    'En caso de no estar de acuerdo con los Términos y Condiciones, por favor, abandone el Sitio Web y absténgase de utilizar los Servicios que se ofrecen. La utilización por el usuario del Sitio Web se entenderá como aceptación plena y sin reservas de los Términos y Condiciones aquí establecidos.',
  text3:
    'En adelante, los términos “Usted/es” y “Usuario/s” serán utilizados para hacer referencia a todas las personas humanas y/o jurídicas que por cualquier razón accedan al Sitio Web. Asimismo, el término “Usuarios Internos” será utilizado específicamente para definir a aquellas personas humanas pertenecientes al Equipo de Value & Access de Amgen, tanto Usuarios In Field como Usuarios Backoffice y, el término “Usuarios Externos” para hacer referencia a las obras sociales, empresas de medicina prepaga o entidades públicas interesadas en el Servicio.'
});

i18next.addResources('es', 'AboutTheWebsite', {
  title: '1 SOBRE EL SITIO WEB',
  text1:
    'A través del Sitio Web, Amgen pone a disposición una herramienta denominada “AMGEpi” que desarrolla análisis para determinar potencialidad de casos en base a datos estadísticos de la incidencia/prevalencia de ciertas patologías en determinadas poblaciones. Para ello, los Usuarios Externos suministrarán información disociada relativa a su población de afiliados distribuida por sexo y edad. Como contraprestación, dichos Usuarios Externos tendrán la posibilidad de acceder a estadísticas anónimas referidas a casos, activos o potenciales, de patologías asociadas a productos de Amgen y otras de relevancia y, de esta forma, obtener previsibilidad sobre los posibles gastos en los que incurrirían por estas patologías.',
  text2:
    'Con el objetivo de alcanzar los fines detallados en el párrafo precedente, se utilizará un algoritmo que efectuará las métricas y estadísticas a partir de los datos que los Usuarios Externos aporten.',
  text3:
    'Cada Usuario Externo tendrá acceso únicamente a su propia información y no a la de los restantes Usuarios Externos, con excepción a la información estadística general.'
});

i18next.addResources('es', 'AccessTheWebsite', {
  title: '2 ACCESO AL SITIO WEB',
  text1:
    '2.1 El acceso y utilización del Sitio Web como de los Servicios ofrecidos a través del Sitio Web exige la previa suscripción o registro tanto de los de los Usuarios Internos como de los Usuarios Externos.',
  text2:
    '2.2 El Usuario se compromete a utilizar el Sitio Web de conformidad con estos Términos y Condiciones, las leyes aplicables en Argentina y con la moral y buenas costumbres.'
});

i18next.addResources('es', 'WebsiteContent', {
  title: '3 CONTENIDO DEL SITIO WEB',
  text1:
    '3.1  Los contenidos de este Sitio Web, tales como texto, información, gráficos, imágenes, logos, marcas, programas de computación, bases de datos, diseños, arquitectura funcional y cualquier otro material (en adelante, el “Contenido”) están protegidos por las leyes aplicables en Argentina incluyendo, pero sin limitación, las leyes sobre derechos de autor, patentes, marcas, modelos de utilidad, diseños industriales y nombres de dominio.',
  text2:
    '3.2 Todo el Contenido es propiedad de Amgen y/o de cualquier otra sociedad vinculada. La compilación, interconexión, operatividad y disposición de los contenidos del Sitio Web es de propiedad exclusiva de Amgen y/o de sus empresas vinculadas. El uso, adaptación, reproducción y/o comercialización no autorizada del Contenido puede encontrarse penado por la legislación vigente en Argentina.',
  text3:
    '3.3 Los Usuarios no copiarán ni adaptarán el código de programación desarrollado por, o por cuenta de, Amgen para generar y operar sus páginas, el cual se encuentra protegido por la legislación aplicable y vigente en Argentina.'
});

i18next.addResources('es', 'PermitedUseWebsite', {
  title: '4 USO PERMITIDO DEL SITIO WEB',
  text1:
    '4.1 El Sitio Web sólo podrá ser utilizado con fines lícitos, para acceder a información referida a los Servicios disponibles a través del mismo. En tal sentido, los Usuario Externos sólo podrán acceder a su propia información.',
  text2:
    '4.2 Los Usuarios tienen prohibido utilizar el Sitio Web para transmitir, distribuir, almacenar o destruir material (i) violando la normativa vigente, (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, honor, privacidad, imagen u otros derechos personales de otras personas.',
  text3:
    '4.3 Los Usuarios tienen prohibido violar o intentar violar la seguridad del Sitio Web, incluyendo pero no limitándose a: (i) el acceso a datos que no estén destinados a tal usuario o entrar en un servidor o cuenta cuyo acceso no está autorizado al Usuario, (ii) evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad o identificación sin la adecuada autorización, (iii) intentar impedir el Servicio a cualquier Usuario, anfitrión o red, incluyendo, pero sin limitación, mediante el envío de virus al Sitio Web, o mediante saturación o ataques de denegación de Servicio, o (iv) enviar correos no pedidos, incluyendo promociones y/o publicidad de productos o servicios.',
  text4:
    '4.4 Las violaciones de la seguridad del sistema o de la red constituyen delitos penales y pueden derivar en responsabilidades civiles. Amgen investigará los casos de violaciones a la seguridad del sistema, pudiendo dirigirse a la autoridad judicial o administrativa competente a los efectos de perseguir a los Usuarios involucrados en tales violaciones.',
  text5:
    '4.5 Amgen se reserva el derecho de suspender o dar de baja a cualquier Usuario que, a exclusivo criterio de Amgen, no cumpla con los estándares definidos en estos Términos y Condiciones o con las políticas vigentes de Amgen, sin que ello genere derecho a resarcimiento alguno.'
});

i18next.addResources('es', 'UserData', {
  title: '5 DATOS PERSONALES DEL USUARIO. REGISTRO EN EL SITIO WEB. ',
  text1: '5.1 El Sitio Web permite el uso de los Servicios por parte de tres tipos de usuarios:',
  text2:
    '5.1.1 Los Usuarios Externos ajenos a Amgen que pueden acceder únicamente a su información y datos estadísticos generales.',
  text3:
    '5.1.2  Los Usuarios Internos que  son los encargados del mantenimiento de la herramienta y de la carga de la información para los cálculos. Asimismo, se encargan de aprobar las cuentas de los Usuarios Externos. Cuentan con acceso a todos los datos suministrados en la plataforma.',
  text4:
    '5.2 Cuando el Usuario Externo se registre en el Sitio Web se le pedirá que aporte a Amgen cierta información que incluirá, entre otras, nombre y apellido, teléfono, dirección de correo electrónico, obra social a la que representa y contraseña. Una vez proporcionada dicha información, Amgen determinará si corresponde su aprobación.',
  text5:
    '5.3 Cuando el Usuario Interno se registre en el Sitio Web se le pedirá que aporte a Amgen cierta información para habilitar la cuenta.',
  text6:
    '5.4 Por favor consulte la Política de Privacidad del Sitio Web para conocer los detalles respecto del tratamiento de sus Datos Personales.',
  text7:
    '5.5 El Usuario será responsable de todos los usos de su cuenta, tanto si están autorizados o no por Usted. Usted deberá notificar inmediatamente a Amgen sobre cualquier uso sin autorización de su cuenta o contraseña.',
  text8:
    '5.6 Los Usuarios registrados y/o que utilicen los Servicios de Amgen garantizan la veracidad, exactitud, vigencia y autenticidad de la información facilitada, y se comprometen a mantenerlos debidamente actualizados, a través del Sitio Web.'
});

i18next.addResources('es', 'ExternalUsersInfo', {
  title: '6 INFORMACIÓN SUMINISTRADA POR USUARIOS EXTERNOS',
  text1:
    '6.1 A través del Sitio Web, los Usuarios Externos podrán suministrar información disociada relacionada a la nómina de sus afiliados.',
  text2:
    '6.2 El Usuario garantiza ser propietario y contar con todos los permisos necesarios para suministrar la información detallada en la cláusula 6.1.',
  text3:
    '6.3 Asimismo, el Usuario autoriza a Amgen a utilizar dicha información a fin de prestar los Servicios ofrecidos a través del Sitio Web.'
});

i18next.addResources('es', 'Minors', {
  title: '7 MENORES DE EDAD',
  text1:
    '7.1 Queda prohibida la utilización del Sitio Web y/o de los Servicios ofrecidos a través del mismo por personas que carezcan de capacidad legal para contratar o menores de edad según la legislación aplicable en Argentina. En caso que ello ocurra, los menores de edad o incapaces deben obtener previamente permiso de sus padres, tutores o representantes legales, quienes serán considerados responsables de todos los actos realizados por las personas a su cargo.'
});

i18next.addResources('es', 'UserResponsability', {
  title: '8 RESPONSABILIDAD DEL USUARIO',
  text1:
    'El Usuario declara y acepta que el uso del Sitio Web sus Servicios y los contenidos se efectúa bajo su única y exclusiva responsabilidad.'
});

i18next.addResources('es', 'ExclusionGuaranteesAndResponsability', {
  title: '9 EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD',
  text1:
    '9.1 Atento al estado de la técnica y a la estructura y funcionamiento de las redes, el Sitio Web no puede confirmar que cada Usuario es quien dice ser.',
  text2:
    '9.2 Amgen no garantiza la disponibilidad y continuidad del funcionamiento del Sitio Web y de los Servicios ofrecidos. No todos los Servicios y contenidos en general se encuentran disponibles para todas las áreas geográficas. Asimismo, Amgen no garantiza la utilidad del Sitio Web y de los Servicios para la realización de ninguna actividad en particular, ni su infalibilidad y, en particular, aunque no de modo exclusivo, que los Usuarios puedan efectivamente utilizar el Sitio Web, acceder a las distintas páginas web que forman el Sitio Web o a aquéllas desde las que se prestan los Servicios.',
  text3:
    '9.3 Amgen no garantiza que el Sitio Web funcione libre de errores o que el Sitio Web y su servidor estén libres de virus informáticos u otros mecanismos lesivos.',
  text4: '9.4 El Sitio Web y los Servicios se suministran tal como están, sin garantías de ninguna clase.',
  text5:
    '9.5 Amgen no garantiza la exactitud, la veracidad, la exhaustividad o la actualización de los contenidos, los Servicios, el software, los textos, los gráficos y los vínculos.',
  text6:
    '9.6 En ningún caso Amgen será responsable de cualquier daño incluyendo, pero sin limitación, daños directos y/o indirectos, lucro cesante o pérdida de chance que resulten del uso o de la imposibilidad de uso del Sitio Web, sin perjuicio de que Amgen haya sido advertido sobre la posibilidad de tales daños.'
});

i18next.addResources('es', 'LinksToAnotherSites', {
  title: '10 VÍNCULOS A OTROS SITIOS',
  text1:
    'En caso de que el Sitio Web contenga vínculos a otros sitios de Internet, Amgen no respalda los contenidos de estos sitios web. Asimismo, Amgen no es responsable del contenido de los sitios web de terceros y no hace ninguna afirmación relativa al contenido o su exactitud en estos sitios web de terceros. Si Usted decide acceder a sitios web de terceras partes vinculados, lo hace a su propio riesgo.'
});

i18next.addResources('es', 'AssignmentUnauthorizedCommercialUse', {
  title: '11 CESIÓN O USO COMERCIAL NO AUTORIZADO',
  text1:
    '11.1 El Usuario acepta no ceder, bajo ningún título, sus derechos u obligaciones bajo estos Términos y Condiciones.',
  text2:
    '11.2 Asimismo, el Usuario se compromete a utilizar el Sitio Web y los Servicios diligentemente y de conformidad con la ley aplicable y vigente y con estos Términos y Condiciones.'
});

i18next.addResources('es', 'Cancellation', {
  title: '12  CANCELACIÓN',
  text1:
    '12.1 Amgen tiene la facultad, y no la obligación, de emitir advertencias, suspensiones temporales y cancelaciones permanentes (baja) de los Usuarios registrados por infracciones a los presentes Términos y Condiciones, y/o cualquier aviso, reglamento de uso e instrucción puestos en conocimiento del Usuario por Amgen.',
  text2:
    '12.2 Sin perjuicio de lo indicado en el punto anterior, Amgen se reserva el derecho, a su exclusivo criterio, de emplear todos los medios legales a su alcance en caso que el Usuario infrinja cualquiera de estos Términos y Condiciones.'
});

i18next.addResources('es', 'Indemnity', {
  title: '13 INDEMNIDAD',
  text1:
    'El Usuario acepta mantener indemne a Amgen de y contra cualquier cargo, acción o demanda, incluyendo, pero no limitándose a, los gastos legales razonables, que resulten del uso que haga del Sitio Web, de los Contenidos y de los Servicios.'
});

i18next.addResources('es', 'General', {
  title: '14 GENERAL',
  text1:
    '14.1 Amgen se reserva el derecho a modificar total o parcialmente estos Términos y Condiciones en cualquier momento. En caso de llevar a cabo alguna modificación, Amgen notificará al Usuario a la dirección de correo electrónico registrada para utilizar el Sitio Web y los Servicios. El Usuario acepta que la notificación por parte de Amgen a dicha dirección de correo electrónico tendrá plena validez como notificación suficiente, y renuncia a cualquier tipo de impugnación respecto de las notificaciones cursadas por Amgen a tal dirección de correo electrónico. Asimismo, si el Usuario persiste en la utilización de los Servicios y/o el Sitio Web, se considerará que ha aceptado implícitamente los nuevos Términos y Condiciones.',
  text2:
    '14.2 En caso de declararse la nulidad de alguna de las cláusulas de estos Términos y Condiciones, tal nulidad no afectará a la validez de las restantes, las cuales mantendrán su plena vigencia y efecto.',
  text3:
    '14.3 Estos Términos y Condiciones, junto con la Política de Privacidad [INSERTAR LINK A POLÍTICA DE PRIVACIDAD] constituyen la totalidad del acuerdo entre Usted y Amgen respecto del uso del Sitio Web.'
});

i18next.addResources('es', 'ApplicableLawAndJurisdiction', {
  title: '15 LEY Y JURISDICCIÓN APLICABLE',
  text1:
    '15.1.1 Estos Términos y Condiciones se rigen por las leyes de la República Argentina. El Usuario se somete a la jurisdicción de los Tribunales Ordinarios de la Ciudad de Buenos Aires, con renuncia expresa a cualquier otro fuero y/o jurisdicción.'
});
