import i18next from 'i18next';

i18next.addResources('es', 'IndicationsAdjuvantBreastCancer', {
  title: 'Cáncer de mama temprano HER2+',
  text:
    'KANJINTI está indicado para el tratamiento de cáncer de mama precoz (CMP) en pacientes adultos con HER2 positivo:\n- después de cirugía, quimioterapia (adyuvante o neoadyuvante) y radioterapia (si procede) (ver Propiedades farmacodinámicas).\n- después de quimioterapia adyuvante con doxorubicina y ciclofosfamida, en combinación con paclitaxel o docetaxel.\n- en combinación con quimioterapia adyuvante consistente en docetaxel y carboplatino.\n- en combinación con quimioterapia neoadyuvante seguido de tratamiento en adyuvancia con KANJINTI para enfermedad localmente avanzada (incluyendo enfermedad inflamatoria) o tumores > 2 cm de diámetro (ver Advertencias y precauciones especiales de uso y Propiedades farmacodinámicas).\nKANJINTI debe emplearse únicamente en pacientes con cáncer de mama metastásico o cáncer de mama precoz, cuyos tumores sobreexpresen HER2 o tengan amplificación del gen HER2 determinados mediante un método exacto y validado (ver Advertencias y precauciones especiales de uso y Propiedades farmacodinámicas).'
});

i18next.addResources('es', 'IndicationsMetastaticBreastCancer', {
  title: 'Cáncer de mama metastásico HER2+',
  text:
    'KANJINTI está indicado para el tratamiento de pacientes adultos con cáncer de mama metastásico (CMM) HER2 positivo: \n- en monoterapia para el tratamiento de aquellos pacientes que hayan recibido al menos dos regímenes quimioterápicos para su enfermedad metastásica. La quimioterapia previa debe haber incluido al menos una antraciclina y un taxano, a menos que estos tratamientos no estén indicados en los pacientes. Los pacientes con receptores hormonales positivos también deben haber fracasado al tratamiento hormonal a menos que éste no esté indicado.\n- en combinación con paclitaxel para el tratamiento de aquellos pacientes que no hayan recibido quimioterapia para su enfermedad metastásica y en los cuales no esté indicado un tratamiento con antraciclinas.\n- en combinación con docetaxel para el tratamiento de aquellos pacientes que no hayan recibido quimioterapia para su enfermedad metastásica.\n- en combinación con un inhibidor de la aromatasa para el tratamiento de pacientes posmenopáusicas con CMM y receptor hormonal positivo, que no hayan sido previamente tratadas con trastuzumab.'
});

i18next.addResources('es', 'IndicationsMetastaticGastricCancer', {
  title: 'Cáncer gástrico metastásico HER2+',
  text:
    'KANJINTI en combinación con capecitabina o 5-fluoracilo y cisplatino, está indicado para el tratamiento de pacientes adultos con adenocarcinoma gástrico o unión gastroesofágica metastásico, HER2 positivo, que no hayan recibido un tratamiento previo para metástasis.\n KANJINTI debe emplearse únicamente en pacientes con cáncer gástrico metastásico (CGM), cuyos tumores sobreexpresen HER2, definido por IHQ 2+ y confirmado por un resultado SISH o FISH o por un resultado IHQ 3+. Se deben emplear métodos exactos y validados (ver Advertencias y precauciones especiales de uso y Propiedades farmacodinámicas).'
});
