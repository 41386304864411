import i18next from 'i18next';

i18next.addResources('es', 'Amgevita', {
  totalSectionLabel: 'Totalidad de la evidencia',
  studiesSectionLabel: 'Estudios Clínicos',
  worldExperienceSectionLabel: 'Experiencia en el mundo',
  autoInjectorSectionLabel: 'Autoinyector Sureclick',
  learnMore: ' Aprenda más',
  sustainabilitySectionLabel: 'Acceso y Sustentabilidad',
  biosimilarsSectionLabel: 'Biosimilares'
});
