import i18next from 'i18next';

i18next.addResources('es', 'Validation', {
  title: 'Sitio web autorizado sólo para profesionales de la salud',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
  exit: 'Salir',
  accept: 'Acepto'
});

i18next.addResources('es', 'ValidationTerms', {
  title: 'AVISO IMPORTANTE',
  paragraph1:
    'La información técnico-científica y/o materiales incluidos en este sitio web son exclusivamente destinados a profesionales de la salud. Los contenidos brindan información general respecto a las propiedades terapéuticas de los medicamentos, actividades de educación médica continua, y diversas iniciativas de Amgen Biotecnología de Argentina S.R.L. y/o sus filiales. Dichos contenidos se encuentran sujetos a periódicas actualizaciones.',
  paragraph2:
    'Para mayor información, por favor contactarse con el área de información médica a través del siguiente e-mail: ',
  email: 'infomed@amgen.com',
  paragraph3:
    'La información sobre los medicamentos que figuran en el presente sitio web no debe ser tomada como consejo médico, diagnóstico o tratamiento.',
  paragraph4:
    'Al hacer click en ACEPTO debajo, Usted acepta expresamente y declara bajo juramento que: (i) es un profesional de la salud matriculado, autorizado y facultado a prescribir o dispensar medicamentos según las leyes y regulaciones vigentes y aplicables en la República Argentina; (ii) comprende que la información y/o materiales incluidos en este sitio web se encuentran exclusivamente destinados a satisfacer la necesidad de mayor información de los profesionales de la salud para un uso adecuado de los medicamentos, y (iii) ha leído y comprendido, y acepta los Términos y Condiciones del presente sitio de Internet. Si Usted no es un profesional de la salud en los términos aquí presentes, deberá hacer click en SALIR.'
});
