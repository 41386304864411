import i18next from 'i18next';

i18next.addResources('es', 'Distribution', {
  distributionTitle: 'Distribuí tus afiliados en los siguiente rangos:',
  distributeThem: 'Distribuilos en los siguientes rangos:',
  ageRange: 'Rango',
  mens: 'Hombres',
  women: 'Mujeres',
  pediatrics: 'Pediátrico (0 a 15 años)',
  adults: 'Adultos (16 a 60 años)',
  olderSixty: 'Mayores (60 a 100+ años)',
  placeholder: 'Ej. 2000'
});
