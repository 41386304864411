import i18next from 'i18next';

i18next.addResources('es', 'Commitment', {
  title: 'Amgen, un compromiso con la medicina biosimilar',
  amgenDetail:
    'Los biosimilares son medicamentos biológicos muy similares a productos de referencia aprobados, esto se refiere a que no existen diferencias clínicas significativas en términos de seguridad y eficacia ni tampoco en cuanto a su calidad.',
  experienceTitle: '40 años de experiencia en biosimilares',
  experienceSubtitle: 'De experiencia en productos biológicos',
  biosimilarTitle: 'Los biosimilares no son genéricos.',
  biosimilarDescription:
    'Tienen un tamaño hasta 1000 veces mayor que el de las moléculas pequeñas de los medicamentos genéricos, y su estructura es mucho más compleja.',
  experience3d: 'Vive la experiencia 3D de un biosimilar',
  discoverBiosimilarTitle: 'Descubre el Biosimilar a través de la realidad aumentada',
  instruction1: 'Tocá el botón para ingresar a la experiencia Web RA*',
  instruction2: 'Hacé click en el botón que se encuentra en el extremo inferior derecho',
  instruction3: 'Buscá una superficie plana y presioná sobre la pantalla para posicionar',
  footNote: '*Para ver la experiencia en realidad aumentada, accede desde tu teléfono o tablet.',
  incompatibleVideoText: 'Tu navegador no es compatible con este video.'
});
