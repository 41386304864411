import { createReducer, completeReducer, completeState } from 'redux-recompose';
import immutable from 'seamless-immutable';

import { actions } from './actions';

const defaultState = { pathologies: { page: [] }, pathology: {}, pathologyPatientFlows: [] };

const initialState = () => immutable(completeState(defaultState));

const reducerDescription = {
  primaryActions: [
    actions.GET_PATHOLOGIES,
    actions.GET_DETAIL,
    actions.GET_STATICS,
    actions.GET_PATIENT_FLOWS
  ],
  override: {
    [actions.CLEAR_PATHOLOGIES]: () => initialState()
  }
};

export default createReducer(initialState(), completeReducer(reducerDescription));
