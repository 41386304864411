import { User } from '../ImportedUser/constants';

export interface ImportUsersState {
  import: {
    count: number;
    totalPages: number;
    totalCount: number;
    currentPage: number;
    nextPage: number | null;
    page: User[];
  };
  importError: boolean;
  importLoading: boolean;
}

export const LIMIT_PER_PAGE = 8;

export const TARGETS = {
  IMPORT: 'import'
};
