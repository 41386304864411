import { Range } from '~components/PopulationWizard/PopulationWizardBasic/components/StepTwoBasic/constants';

import api from '../config/api';

export const sendDistribution = (values: any) =>
  api.post('/pyramids/estimate', {
    pyramid: {
      groups: values.groups.length ? values.groups : [{}],
      totalPopulation: values.total,
      name: values.name,
      oldName: values.oldName || ''
    }
  });

export const estimateDistribution = (groups: Range[]) =>
  api.post('/pyramids/estimate/ranges', { pyramid: { groups } });

export const confirmDistribution = (values: any) =>
  api.put('/pyramids/active', {
    pyramid: {
      groups: values.groups.length ? values.groups : [],
      totalPopulation: values.total,
      name: values.name,
      oldName: values.oldName
    }
  });

export const getPyramids = () => api.get('/pyramids/all_active');

export const deletePyramid = (id: number) => api.delete(`/pyramids/${id}`);
