enum Routes {
  HOME = '/dashboard',
  AUTHENTICATION = '/authentication',
  LOGIN = '/authentication/login',
  RECOVER_PASSWORD = '/authentication/forgot-password',
  SET_PASSWORD = '/authentication/set-password',
  SIGNUP = '/authentication/signup',
  SIGNUP_SUCCESS = '/authentication/signup/success',
  TERMS_AND_CONDITIONS = '/authentication/signup/terms',
  STATUS = '/authentication/status',
  DETAIL = '/dashboard/detail/:id',
  EDIT_PATIENT_FLOW = '/dashboard/detail/:id/:patientFlowId/:patientFlowItemId',
  LANDING_HOME = '/',
  LANDING_LOGIN = '/login',
  VALIDATION = '/validation',
  CLINICAL_ESSAYS = '/clinical_essays'
}

export default Routes;
