import i18next from 'i18next';

i18next.addResources('es', 'InjectorSection', {
  title: 'Autoinyector Sureclick®',
  subtitle: 'Fácil de usar',
  presentation: 'Presentación: 40 mg/0,8 mL',
  trait1: 'Autoinjección subcutánea',
  trait2: 'Caja con 2 autoinyectores',
  trait3: 'Facilita la autoadministración',
  injector: 'Inyector',
  infoBubble: 'Libre de Citrato',
  videoTitle: '¿Cómo utilizar el dispositivo SureClick(R)?'
});
