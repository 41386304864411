import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';
import { ApiResponse } from 'apisauce';

import {
  getSnapshots,
  sendSnapshot,
  editSnapshot,
  deleteSnapshot,
  updateSnapshot
} from 'services/SnapshotServices';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import { actionCreators as pathologyActions } from '~redux/Pathologies/actions';
import { Modals } from '~redux/Modal/constants';

import { TARGETS, HTTP_STATUS_CODES, HTTP_RESPONSES, ResponseFailData, ACTIONS } from './constants';

export const actions = createTypes(completeTypes(ACTIONS), ['@@SNAPSHOTS']);

export const actionsCreator = {
  getSnapshots: (id: number, page: number) => ({
    type: actions.GET_SNAPSHOTS,
    target: TARGETS.SNAPSHOTS,
    service: getSnapshots,
    payload: { id, page }
  }),
  sendSnapshots: (snapshotName: string, id: number, callback: () => void) => ({
    type: actions.SEND_SNAPSHOT,
    target: TARGETS.SNAPSHOT,
    service: sendSnapshot,
    payload: { snapshotName, id },
    injections: [withPostSuccess(() => callback())],
    failureSelector: (response: ApiResponse<ResponseFailData>) => {
      const { status } = response;
      if (status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
        return HTTP_RESPONSES.UNPROCESSABLE_ENTITY;
      }
      return HTTP_RESPONSES.CLIENT_ERROR;
    }
  }),
  saveSnapshotName: (name: string) => ({
    type: actions.SAVE_SNAPSHOT,
    target: TARGETS.NAME,
    payload: name
  }),
  editSnapshot: (snapshotName: string, pathologyId: number, snapshotId: number) => ({
    type: actions.UPDATE_SNAPSHOT,
    target: TARGETS.SNAPSHOT,
    service: editSnapshot,
    payload: { snapshotName, pathologyId, snapshotId },
    injections: [
      withPostSuccess((dispatch: Function) => {
        dispatch(actionsCreator.getSnapshots(pathologyId, 1));
      })
    ]
  }),
  deleteSnapshot: (pathologyId: number, snapshotId: number) => ({
    type: actions.DELETE_SNAPSHOT,
    target: TARGETS.SNAPSHOT,
    service: deleteSnapshot,
    payload: { pathologyId, snapshotId },
    injections: [
      withPostSuccess((dispatch: Function) => {
        dispatch(actionsCreator.getSnapshots(pathologyId, 1));
        dispatch(modalActions.closeModal(Modals.deleteScenarioModal));
      })
    ]
  }),
  setSnapshotChanged: (snapshotChanged: boolean) => ({
    type: actions.SNAPSHOT_CHANGED,
    target: TARGETS.SNAPSHOT_CHANGED,
    payload: snapshotChanged
  }),
  updateSnapshot: (pathologyId: number, snapshotId: number | null) => ({
    type: actions.UPDATE_SNAPSHOT,
    target: TARGETS.SNAPSHOT,
    service: updateSnapshot,
    payload: { pathologyId, snapshotId },
    injections: [
      withPostSuccess((dispatch: Function) => {
        dispatch(pathologyActions.getPathologyPatientFlows(pathologyId, undefined, undefined));
      })
    ]
  })
};
