import { createReducer, completeReducer, completeState } from 'redux-recompose';
import immutable from 'seamless-immutable';

import { actions } from './actions';
import { getDefaultState, LoginState, PopulationAmount } from './constants';

const initialState = () => immutable(completeState(getDefaultState()));

const reducerDescription = {
  primaryActions: [
    actions.LOGIN_USER,
    actions.LOGOUT_USER,
    actions.GET_USER,
    actions.RESTORE_POPULATION,
    actions.GET_USER_POPULATION,
    actions.UPDATE_POPULATION,
    actions.RECOVER_PASSWORD,
    actions.SET_PASSWORD
  ],
  override: {
    [actions.CLEAR_USER]: () => initialState(),
    [`${actions.RESTORE_POPULATION}_SUCCESS`]: (
      state: LoginState
    ): immutable.ImmutableObject<LoginState<object>> =>
      immutable<LoginState<object>>({
        ...state,
        populationAmount: {},
        populationAmountLoading: false
      }),
    [actions.UPDATE_POPULATION]: (
      state: LoginState,
      { payload }: { payload: { amount: number; name: string; oldName?: string } }
    ) =>
      immutable({
        ...state,
        populationAmount: {
          ...state.populationAmount,
          totalPopulation: payload.amount,
          name: payload.name,
          oldName: payload.oldName
        }
      }),
    [actions.CLEAR_USER_POPULATION]: (state: LoginState) =>
      immutable({
        ...state,
        populationAmount: {},
        populationAmountLoading: false
      }),
    [actions.SET_USER_POPULATION]: (state: LoginState, payload: { payload: PopulationAmount }) =>
      immutable({ ...state, populationAmount: payload.payload })
  }
};

export default createReducer(initialState(), completeReducer(reducerDescription));
