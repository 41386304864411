import i18next from 'i18next';

i18next.addResources('es', 'AmgevitaHero', {
  title: 'Con aprobación de FDA, Ema y ANMAT',
  secondTitle: 'El primer biosimilar de adalimumab que cuenta con la Totalidad de la Evidencia',
  descriptionTitle: 'El respaldo detrás de <strong>AMGEVITA®</strong> hace la diferencia',
  allTheEvidence: 'Amgevita cuenta con la Totalidad de la Evidencia',
  efficacyAndSafety: 'Eficacia y Seguridad: evidencia clínica robusta',
  experience: 'Más de 50.000 pacientes tratados a nivel mundial³',
  sureclick: 'Dispositivo Sureclick®\nFácil auto-administración\nFormulación libre de citrato',
  patientReminderLabel: 'Tarjeta de Recordatorio para Pacientes'
});
