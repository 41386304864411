import i18next from 'i18next';

i18next.addResources('es', 'PatientFlowHeaderRow', {
  state: 'Estado',
  percentage: 'Porcentaje',
  patient: 'Pacientes',
  costAmgen: 'Costo Amgen',
  costAmgenModal: '¿Cómo se calcula el costo del tratamiento de AMGEN?',
  costComparator: 'Costo Comparador',
  costComparatorModal: '¿Cómo se calcula el costo del tratamiento comparador?',
  costCustom: 'Costo Personalizado',
  costCustomButtom: 'Calcular',
  costCustomTitle: 'Ingresá el costo por paciente para realizar el cálculo:',
  costCustomPlaceholder: 'Ej. $2.000',
  costPerPatient: '$ {{value}} p/paciente'
});
