import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Redirect } from 'react-router-dom';

import { history } from '~redux/store';
import { CURRENT_ENV } from '~constants/environments';

import Suspense from '../Suspense';

import styles from './styles.module.scss';
import { ROUTE_COMPONENTS } from './constants';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import { DEFAULT_PUBLIC_ROUTE } from './components/constants';
import ScrollToTop from './components/ScrollToTop';

function AppRoutes() {
  const applicationRoutes = ROUTE_COMPONENTS[CURRENT_ENV];
  const publicRouteRoot = DEFAULT_PUBLIC_ROUTE[CURRENT_ENV];

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <div className={styles.container}>
        <Suspense>
          <Switch>
            <AuthenticatedRoute
              isPublicRoute
              path={publicRouteRoot.path}
              component={publicRouteRoot.component}
            />
            <AuthenticatedRoute
              isPrivateRoute
              path={applicationRoutes.path}
              component={applicationRoutes.component}
            />
            <Redirect to={publicRouteRoot.path} />
          </Switch>
        </Suspense>
      </div>
    </ConnectedRouter>
  );
}

export default AppRoutes;
