import i18next from 'i18next';

i18next.addResources('es', 'Validations', {
  shortInput: 'La información administrada es demasiado corta.',
  longInput: 'La información administrada es demasiado larga.',
  shortPasswordInput: 'La contraseña es demasiado corta.',
  required: 'Campo obligatorio.',
  emailFormatError: 'Formato de mail inválido.',
  phoneFormat: 'Ej: +541148293720',
  numberNeeded: 'Debe contener números.',
  numbersNotAllowed: 'No debe contener números.',
  onlyAlphabeticCharacters: 'Solo se permiten caracteres alfabéticos, apóstrofe, guion y tildes.',
  invalidField: 'Campo inválido.',
  passwordsMustMatch: 'Las contraseñas deben ser iguales.',
  positive: 'Debe ser un número positivo.',
  greaterThanZero: 'Debe ser mayor a cero.',
  passwordRequired: 'Debe ingresar una contraseña',
  passwordLength: 'La contraseña debe tener un mínimo de {{lenght}} caracteres.',
  passwordFormat:
    'La contraseña debe tener un número, una letra en mayúscula, una en minúscula y un caracter especial'
});

i18next.addResources('es', 'RequestErrors', {
  TIMEOUT_ERROR: 'Ha ocurrido un error. Por favor intente nuevamente.'
});
