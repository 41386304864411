import i18next from 'i18next';

i18next.addResources('es', 'ImportantSafetyInformation', {
  title: 'Informacion de Seguridad Importante',
  text:
    'ADVERTENCIAS en recuadro: Cardiomiopatía: la administración de productos de trastuzumab puede provocar insuficiencia cardíaca subclínica y clínica. La incidencia y la gravedad fueron más altas en pacientes que recibieron trastuzumab con regímenes de quimioterapia con antraciclina.\nEvaluar la función ventricular izquierda en todos los pacientes antes y durante el tratamiento con KANJINTI ™. Suspender el tratamiento con KANJINTI ™ en pacientes que reciben terapia adyuvante y suspender KANJINTI ™ en pacientes con enfermedad metastásica para una disminución clínicamente significativa de la función ventricular izquierda\nReacciones de infusión; Toxicidad pulmonar: la administración de productos de trastuzumab puede provocar reacciones de infusión graves y fatales y toxicidad pulmonar. Los síntomas generalmente ocurren durante o dentro de las 24 horas posteriores a la administración. Interrumpa la infusión de KANJINTI ™ para disnea o hipotensión clínicamente significativa. Monitoree a los pacientes hasta que los síntomas se resuelvan por completo. Suspenda KANJINTI ™ para anafilaxia, angioedema, neumonitis intersticial o síndrome de dificultad respiratoria aguda\nToxicidad embrio-fetal: la exposición a productos trastuzumab durante el embarazo puede provocar oligohidramnios y secuencias de oligohidramnios que se manifiestan como hipoplasia pulmonar, anomalías esqueléticas y muerte neonatal. Informar a los pacientes sobre estos riesgos y la necesidad de una anticoncepción efectiva.',
  close: 'Cerrar'
});

i18next.addResources('es', 'AdditionalImportantSafetyInformation', {
  title: 'Información de seguridad importante adicional',
  text: ''
});

i18next.addResources('es', 'Cardiomyopathy', {
  title: 'Cardiomiopatía',
  itemInfo1:
    'La administración de productos con trastuzumab puede resultar en insuficiencia cardíaca subclínica y clínica. La incidencia y la gravedad fueron mayores en pacientes que recibieron trastuzumab con regímenes de quimioterapia con antraciclinas. En un estudio clínico pivotal del cáncer de mama en adyuvancia, un paciente que desarrolló ICC falleció por una cardiomiopatía.',
  itemInfo2:
    'Los productos con trastuzumab pueden causar insuficiencia ventricular izquierda, arritmias, hipertensión, insuficiencia cardíaca incapacitante, cardiomiopatía y muerte por causas cardíacas.',
  itemInfo3:
    'Los productos con trastuzumab pueden causar un deterioro asintomático de la fracción de eyección del ventrículo izquierdo (FEVI).',
  itemInfo4:
    'Discontinúe el tratamiento con KANJINTI™ en pacientes que reciben tratamiento adyuvante para el cáncer de mama e interrúmpalo en pacientes con enfermedad metastásica en caso de un deterioro clínicamente significativo de la función del ventrículo izquierdo.'
});

i18next.addResources('es', 'CardiacMonitoring', {
  title: 'Monitoreo cardíaco',
  itemInfo1:
    'Evalúe la función cardíaca antes y durante el tratamiento. Respecto del tratamiento adyuvante para el cáncer de mama, evalúe también la función cardíaca luego de finalizado el tratamiento con KANJINTI™.',
  itemInfo2:
    'Realice una evaluación cardíaca exhaustiva, que incluya la historia clínica, exámenes físicos y la determinación de la FEVI mediante un ecocardiograma o un escaneo MUGA.',
  itemInfo3:
    'Monitoree periódicamente al paciente en caso de un deterioro de la función del ventrículo izquierdo durante y después del tratamiento con KANJINTI™.',
  itemInfo4:
    'Realice monitoreos con mayor frecuencia si se interrumpe KANJINTI™ en caso de una disfunción ventricular izquierda significativa.'
});

i18next.addResources('es', 'InfusionReaction', {
  title: 'Reacciones a la infusión',
  itemInfo1: 'La administración de KANJINTI™ puede causar reacciones a la infusión graves y mortales.',
  itemInfo2:
    'Por lo general, los síntomas ocurren durante o dentro de las 24 horas de la administración de KANJINTI™.',
  itemInfo3:
    'Interrumpa la infusión de KANJINTI™ en caso de disnea o hipotensión clínicamente significativa.',
  itemInfo4: 'Monitoree a los pacientes hasta que los síntomas se resuelvan por completo.',
  itemInfo5:
    'Discontinúe KANJINTI™ en caso de reacciones a la infusión que se manifiesten como anafilaxia, angioedema, neumonitis intersticial o síndrome de dificultad respiratoria aguda. Considere seriamente la discontinuación permanente en todos los pacientes que presenten reacciones a la infusión severas.',
  itemInfo6:
    'Las reacciones a la infusión consisten en un conjunto de síntomas que se caracteriza por fiebre y escalofríos, y algunas veces incluye náuseas, vómitos, dolor (en algunos casos en los sitios tumorales), cefalea, mareos, disnea, hipotensión, erupción cutánea y astenia.'
});

i18next.addResources('es', 'EmbryoFetalToxicity', {
  title: 'Toxicidad embriofetal',
  itemInfo1:
    'La exposición a productos con trastuzumab durante el embarazo puede causar oligohidramnios y secuencia de oligohidramnios que se manifiesta como hipoplasia pulmonar, anomalías óseas y muerte neonatal. Informe a los pacientes sobre estos riesgos y la necesidad de utilizar un método anticonceptivo efectivo.',
  itemInfo2:
    'Verifique si las mujeres en edad fértil están embarazadas antes de iniciar la administración de KANJINTI™.',
  itemInfo3:
    'Informe a las mujeres embarazas y aquellas en edad fértil que la exposición a KANJINTI™ durante el embarazo o dentro de los 7 meses previos a la concepción puede causar daño fetal.',
  itemInfo4:
    'Recomiende a las mujeres en edad fértil que utilicen un método anticonceptivo efectivo durante el tratamiento y durante al menos 7 meses tras la última dosis de KANJINTI™. Indique a las pacientes mujeres que se contacten con el profesional de la salud en caso de saber o sospechar que están embarazadas.',
  itemInfo5:
    'Considere los beneficios de la lactancia para el desarrollo y la salud junto con la necesidad clínica de la madre de recibir tratamiento con KANJINTI™ y los posibles efectos adversos de KANJINTI™ o de la enfermedad subyacente de la madre en el lactante.'
});

i18next.addResources('es', 'PulmonaryToxicity', {
  title: 'Toxicidad pulmonar',
  itemInfo1:
    'Los productos con trastuzumab pueden causar toxicidad pulmonar grave o mortal, lo que incluye disnea, neumonitis intersticial, infiltrados pulmonares, derrame pleural, edema pulmonar no cardiogénico, insuficiencia pulmonar e hipoxia, síndrome de dificultad respiratoria aguda y fibrosis pulmonar. Dichos eventos pueden ocurrir como secuelas de las reacciones a la infusión.',
  itemInfo2:
    'Los pacientes con enfermedad pulmonar intrínseca sintomática o afectación tumoral extensiva de los pulmones, que resulta en disnea en reposo, presentan un nivel de toxicidad más severo.',
  itemInfo3: 'Discontinúe la administración de KANJINTI™ en pacientes que experimentan toxicidad pulmonar.'
});

i18next.addResources('es', 'Exacerbation', {
  title: 'Exacerbación de neutropenia inducida por quimioterapia',
  itemInfo1:
    'En estudios clínicos aleatorizados y controlados, las incidencias por pacientes de neutropenia grado 3-4 según el sistema NCI-CTC y de neutropenia febril fueron mayores en pacientes que recibieron trastuzumab en combinación con quimioterapia mielosupresora en comparación con aquellos que recibieron solo quimioterapia. La incidencia de muerte séptica fue similar entre los pacientes que recibieron trastuzumab y los que no.'
});

i18next.addResources('es', 'AdverceReactions', {
  title: 'Reacciones adversas más frecuentes',
  itemInfo1:
    'Las reacciones adversas más frecuentes relacionadas con la administración de productos con trastuzumab para el cáncer de mama fueron fiebre, náuseas, vómitos, reacciones a la infusión, diarrea, infecciones, aumento de tos, cefalea, cansancio, disnea, erupción cutánea, neutropenia, anemia y mialgia.',
  itemInfo2:
    'Las reacciones adversas más frecuentes relacionadas con la administración de productos con trastuzumab para el cáncer gástrico metastásico fueron neutropenia, diarrea, cansancio, anemia, estomatitis, pérdida de peso, infección en las vías respiratorias superiores, fiebre, trombocitopenia, inflamación de la mucosa, nasofaringitis y disgeusia.'
});
