import { createReducer, completeReducer, completeState, onSuccess, onDelete } from 'redux-recompose';
import immutable from 'seamless-immutable';

import { actionsCreator } from './actions';

const initialState = completeState({
  pyramid: { page: [] },
  estimatedDistribution: { groups: [] },
  estimatedPyramid: null,
  pyramids: []
});

const reducerDescription = {
  primaryActions: [
    actionsCreator.SEND_DISTRIBUTION,
    actionsCreator.CONFIRM_DISTRIBUTION,
    actionsCreator.ESTIMATE_DISTRIBUTION,
    actionsCreator.GET_PYRAMIDS,
    actionsCreator.DELETE_PYRAMID
  ],
  override: {
    [actionsCreator.UPDATE_BASIC_DISTRIBUTION]: onSuccess(),
    [actionsCreator.DELETE_PYRAMID_SUCCESS]: onDelete((action: { payload: any }) => action.payload)
  }
};

export default createReducer(immutable(initialState), completeReducer(reducerDescription));
