import i18next from 'i18next';

i18next.addResources('es', 'Dosage', {
  title: 'Dosificación idéntica al producto de referencia IV en todas las indicaciones',
  seeMore: 'Ver Informacion de Seguridad Importante y adicional'
});

i18next.addResources('es', 'EarlyBreastCancerDosage', {
  title: 'Cáncer de mama precoz',
  text:
    'Pauta semanal y cada 3 semanas\nEn la pauta cada tres semanas la dosis de inicio recomendada de KANJINTI es de 8 mg/kg de peso. La dosis de mantenimiento recomendada de KANJINTI es de 6 mg/kg de peso cada tres semanas, comenzando tres semanas después de la dosis de inicio.\nEn la pauta semanal se debe administrar una dosis inicial de 4 mg/kg seguida de 2 mg/kg cada semana, de forma concomitante con paclitaxel tras quimioterapia con doxorubicina y ciclofosfamida.\nVer Propiedades farmacodinámicas para tratamiento de combinación con quimioterapia.'
});

i18next.addResources('es', 'MetastaticBreastCancerDosage', {
  title: 'Cáncer de mama metastásico',
  text:
    'Pauta cada 3 semanas\nLa dosis de inicio recomendada es de 8 mg/kg de peso. La dosis de mantenimiento recomendada es de 6 mg/kg de peso cada tres semanas, comenzando tres semanas después de la dosis de inicio.\nPauta semanal\nLa dosis de inicio recomendada de KANJINTI es de 4 mg/kg de peso. La dosis semanal de mantenimiento recomendada de KANJINTI es de 2 mg/kg de peso, comenzando una semana después de la dosis de inicio.\nAdministración en combinación con paclitaxel o docetaxel\nEn los estudios pivotales de trastuzumab, paclitaxel o docetaxel fueron administrados el día siguiente tras la dosis de inicio de trastuzumab (para información acerca de la dosis, ver la Información para Prescribir de paclitaxel o docetaxel) e inmediatamente tras las dosis siguientes de trastuzumab si la dosis precedente de trastuzumab fue bien tolerada.\nAdministración en combinación con un inhibidor de la aromatasa\nEn el estudio pivotal se administró trastuzumab junto con anastrozol desde el día 1. No hubo restricciones acerca de cómo administrar en el tiempo trastuzumab y anastrozol (para información acerca de la dosis, ver la Información para Prescribir de anastrozol o de otros inhibidores de la aromatasa).'
});

i18next.addResources('es', 'MetastaticGastricCancerDosage', {
  title: 'Cáncer gástrico metastásico',
  text:
    'Pauta cada 3 semanas\n\n La dosis de inicio recomendada es de 8 mg/kg de peso. La dosis de mantenimiento recomendada es de 6 mg/kg de peso cada tres semanas, comenzando tres semanas después de la dosis de inicio.'
});
