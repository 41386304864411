import { useSelector } from 'react-redux';

import { State } from '~redux/types';
import { LOGGED_FLAG, CURRENT_ENV, ENVIRONMENTS, LOGIN_LANDINGS } from '~constants/environments';

const LANDINGS = [ENVIRONMENTS.AMGEVITA, ENVIRONMENTS.KANJINTI];

function useValidateSession() {
  const currentUser = useSelector((state: State) => state.login.user);
  if (LANDINGS.includes(CURRENT_ENV) && LOGIN_LANDINGS === 'false') {
    return sessionStorage.getItem(LOGGED_FLAG);
  }
  return currentUser?.token;
}

export default useValidateSession;
