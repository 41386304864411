import i18next from 'i18next';

i18next.addResources('es', 'Footer', {
  address: 'Italia 415, 2° Piso B1638BMC - Vicente López Buenos Aires, Argentina.',
  title: 'Kanjinti',
  contactTitle: 'Contáctenos',
  contactData: 'Por consultas, por favor contáctese <strong>AmgenAR@amgen.com</strong>',
  subtitle: 'Amgen Argentina',
  description: 'Amgen Biotecnología de Argentina S.R.L.',
  web: 'Visita nuestra web <strong>www.amgen.com</strong>',
  legal: '© 2020 Amgen S.A. Todos los derechos reservados.',
  footerDataLine2: 'Información para prescribir',
  footerDataLine3: 'Escanear para ver IPP',
  prospect: 'Accedé al prospecto',
  legalAdvertising: 'Aviso Legal',
  privacyPolicy: 'Política de Privacidad'
});
