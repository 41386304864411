import i18next from 'i18next';

i18next.addResources('es', 'kanjintiTotalEvidence', {
  title: 'Totalidad de la evidencia',
  subtitle: 'Los biosimilares requieren soporte regulatorio sólido',
  messageOne:
    'Para la aprobación de un biosimilar el fabricante debe demostrar la alta similitud con el producto de referencia en cuanto a la calidad, seguridad y la eficacia.',
  messageTwo:
    'En particular el biosimilar debe demostrar que no presenta diferencias significativas con el producto de referencia.',
  weCountTitle: 'Contamos con la totalidad de la evidencia ',
  weCountMessageOne:
    'Los biosimilares se elaboran para que sean casi idénticos al medicamento biológico de referencia. Sin embargo, no es posible realizar copias exactas de productos biológicos dado que se crean a partir de células vivas.',
  weCountMessageTwo:
    'Los biosimilares se someten a pruebas estrictas para demostrar que pueden producir resultados similares a los del medicamento biológico de referencia. KANJINTI® es fabricado por Amgen®, un líder mundial en biotecnología.',
  moreData: 'Conoce más sobre la totalidad de la evidencia'
});
