import { createReducer, completeReducer, completeState } from 'redux-recompose';
import immutable from 'seamless-immutable';

import { actions } from './actions';
import { DEFAULT_STATE } from './constants';

const initialState = completeState(DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [actions.SIGNUP, actions.CHECK_USER]
};

export default createReducer(immutable(initialState), completeReducer(reducerDescription));
