import i18next from 'i18next';

i18next.addResources('es', 'InfieldImport', {
  title: 'Importar afiliados',
  description: 'Seleccioná entre las {{total}} poblaciones disponibles',
  financer: 'Financiador',
  user: 'Usuario',
  date: 'Fecha',
  import: 'Importar',
  pagination: 'Mostrando {{showingFrom}} - {{showingTo}} de {{total}}'
});
