export const TARGETS = {
  USER: 'user',
  TEMP_USER: 'tempUser'
};

export const DEFAULT_STATE = {
  user: null,
  tempUser: null
};

export interface SignupState {
  tempUser: User;
}

export interface User {
  first_name: string;
  last_name: string;
  entity: string;
  phone: string;
  email: string;
  password: string;
}
