import i18next from 'i18next';

import { invalidCredentials, rejected, pending, tooManyRequests } from './constants';

i18next.addResources('es', 'Login', {
  title: 'Bienvenido a {{title}}',
  login: 'Iniciar sesión',
  forgotPassword: '¿Olvidaste tu contraseña?',
  email: 'E-mail',
  password: 'Contraseña',
  [invalidCredentials]: 'Usuario o contraseña inválida. Volvé a intentarlo.',
  invalidFormat: 'Formato no válido',
  register: 'Registrarse',
  recover: '¿Olvidaste tu contraseña?',
  [rejected]: 'Usuario rechazado',
  [pending]: 'Usuario pendiente de aprobación',
  powered: 'powered by',
  [tooManyRequests]:
    'Se han registrado múltiples intentos de acceso incorrecto. Vuelva a intentarlo más tarde.'
});
