import i18next from 'i18next';

i18next.addResources('es', 'kanjintiReferenceBiologic', {
  clinicalStudiesTitle: 'Estudios clínicos',
  clinicalStudiesMsg: '(Eficacia, seguridad, inmunogenicidad)',
  clinicalPharmacologyTitle: 'Farmacología clínica',
  clinicalPharmacologyMsg: '(FC/FD)*',
  preClinical: 'Estudios pre-clínicos',
  analaticFeaturingTitle: 'Caracterización analítica',
  analaticFeaturingMsg: '(Valoración de estructura y función)'
});
