import i18next from 'i18next';

i18next.addResources('es', 'PopulationWizard', {
  loadFile: 'Cargar archivo',
  dropFile: 'Arrastra y suelta tus archivos aquí o ',
  selectFile: 'selecciona desde tu ordenador.',
  loadFileSuccess: ' cargado exitosamente.',
  formatPermited: 'Formato .CSV permitido',
  importCSV: 'Importar .CSV'
});
