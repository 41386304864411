import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { init as initApm } from '@elastic/apm-rum';
import { Helmet } from 'react-helmet';

import ScrollEventProvider from '~utils/customHooks/useScroll';
import ViewportProvider from '~utils/customHooks/useViewport';
import { CURRENT_ENV, ENVIRONMENTS, EPIDATA_APP_NAME } from '~constants/environments';

import { apiSetup } from '../config/apiSetup';
import store from '../redux/store';

import Routes from './components/Routes';

import '../scss/application.scss';
import 'react-image-lightbox/style.css';

initApm({
  serviceName: process.env.REACT_APP_KIBANA_SERVICE_NAME,
  serverUrl: process.env.REACT_APP_KIBANA_SERVER_URL,
  environment: process.env.REACT_APP_KIBANA_APM_ENVIRONMENT,
  serviceVersion: '',
  distributedTracingOrigins: [`${process.env.REACT_APP_KIBANA_TRACING_URL}`]
});

const capitalizeTest = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

class App extends Component {
  static defaultProps = {
    loading: false
  };

  componentDidMount() {
    apiSetup(store.dispatch);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {capitalizeTest(
              CURRENT_ENV === ENVIRONMENTS.EPIDATA
                ? EPIDATA_APP_NAME.toLowerCase()
                : CURRENT_ENV.toLowerCase()
            )}
          </title>
        </Helmet>
        <ViewportProvider>
          <ScrollEventProvider>
            <Provider store={store}>
              <Routes />
            </Provider>
          </ScrollEventProvider>
        </ViewportProvider>
      </>
    );
  }
}

export default App;
