import { PatientFlow } from '~redux/Pathologies/constants';

import api from '../config/api';

// TODO: Remove limit when implementing pagination

export const getPathologies = ({
  page,
  pyramidId,
  user
}: {
  page: number;
  pyramidId?: number;
  user?: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
}) => api.get('/pathologies', { page, limit: 250, pyramid_id: pyramidId, user });

export const getPathologyDetail = ({
  id,
  pyramidId,
  user
}: {
  id: number;
  pyramidId?: number;
  user?: number;
}) =>
  // eslint-disable-next-line @typescript-eslint/camelcase
  api.get(`/pathologies/${id}`, { pyramid_id: pyramidId, user });

export const getPathologyStatics = ({
  id,
  pyramidId,
  user
}: {
  id: number;
  pyramidId?: number;
  user?: number;
}) =>
  // eslint-disable-next-line @typescript-eslint/camelcase
  api.get(`/pathologies/${id}/statistics`, { pyramid_id: pyramidId, user });

export const editPatientFlow = ({
  patientFlows,
  patientFlowId
}: {
  patientFlows: PatientFlow[];
  patientFlowId: number;
}) =>
  api.put(`/patient_flows/${patientFlowId}/customize`, {
    flowCharacters: patientFlows
  });

export const getPathologyPatientFlows = ({
  id,
  pyramidId,
  user
}: {
  id: number;
  pyramidId?: number;
  user?: number;
}) =>
  api.get(`/pathologies/${id}/patient_flows`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    pyramid_id: pyramidId,
    user
  });

export const editScenario = ({ patientFlowId, scenarioId }: { patientFlowId: number; scenarioId: number }) =>
  api.put(`/patient_flows/${patientFlowId}/select_scenario`, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    scenario_id: scenarioId
  });
