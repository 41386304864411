import { createReducer, completeReducer, completeState } from 'redux-recompose';
import immutable from 'seamless-immutable';

import { SnapshotState } from './constants';
import { actions } from './actions';

const initialStateDescription = {
  snapshots: [],
  snapshotChanged: false,
  name: null
};

const initialState = completeState(initialStateDescription);

const reducerDescription = {
  primaryActions: [
    actions.GET_SNAPSHOTS,
    actions.SEND_SNAPSHOT,
    actions.UPDATE_SNAPSHOT,
    actions.DELETE_SNAPSHOT
  ],
  override: {
    [actions.SNAPSHOT_CHANGED]: (state: SnapshotState, payload: { payload: boolean }) =>
      immutable({
        ...state,
        snapshotChanged: payload.payload
      }),
    [actions.SAVE_SNAPSHOT]: (state: SnapshotState, payload: { payload: string }) =>
      immutable({ ...state, name: payload.payload })
  }
};

export default createReducer(immutable(initialState), completeReducer(reducerDescription));
