import i18next from 'i18next';

import { invalidCredentials } from '../Login/constants';

i18next.addResources('es', 'SetPassword', {
  title: 'Reestablecer contraseña',
  subtitle: 'Ingresá una nueva contraseña para continuar.\nDebe contener al menos 8 caracteres.',
  send: 'Guardar contraseña',
  back: 'Iniciar sesión',
  successTitle: '¡Tu contraseña fue cambiada con éxito!',
  [invalidCredentials]: 'Error en la solicitud. Volvé a intentarlo.'
});
