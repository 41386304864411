export interface ResponseFailData {
  success: boolean;
  errors: string[];
}

export interface Snapshot {
  id: number;
  createdAt: string;
  name: string;
}

export interface SnapshotState {
  name: string;
  snapshot: Snapshot;
  snapshots: {
    page: Snapshot[];
    count: number;
    totalPages: number;
    totalCount: number;
    currentPage: number;
    nextPage: number | null;
    previousPage: number | null;
  };
  snapshotsLoading: boolean;
  snapshotChanged: boolean;
}

export const TARGETS = {
  NAME: 'name',
  SNAPSHOTS: 'snapshots',
  SNAPSHOT: 'snapshot',
  SNAPSHOT_CHANGED: 'snapshotChanged'
};

export const HTTP_STATUS_CODES = {
  UNPROCESSABLE_ENTITY: 422
};

export const HTTP_RESPONSES = {
  CLIENT_ERROR: 'CLIENT_ERROR',
  UNPROCESSABLE_ENTITY: 'unprocessableEntity'
};

export const ACTIONS = [
  'GET_SNAPSHOTS',
  'SEND_SNAPSHOT',
  'SAVE_SNAPSHOT',
  'UPDATE_SNAPSHOT',
  'DELETE_SNAPSHOT',
  'SNAPSHOT_CHANGED'
];
