export enum ENVIRONMENTS {
  KANJINTI = 'KANJINTI',
  AMGEVITA = 'AMGEVITA',
  EPIDATA = 'EPIDATA'
}

export const EPIDATA_APP_NAME = 'AMGEpi';

export const LOGIN_LANDINGS = process.env.REACT_APP_LOGIN_LANDING;

export const DOMAINS = {
  KANJINTI: process.env.REACT_APP_KANJINTI_DOMAIN,
  AMGEVITA: process.env.REACT_APP_AMGEVITA_DOMAIN,
  EPIDATA: process.env.REACT_APP_EPIDATA_DOMAIN
};

export const CURRENT_DOMAIN = window.location.host;

console.log(CURRENT_DOMAIN);


export const CURRENT_ENV: ENVIRONMENTS = (() => {
  switch (CURRENT_DOMAIN) {
    case DOMAINS.KANJINTI:
      return ENVIRONMENTS.KANJINTI;
    case DOMAINS.AMGEVITA:
      return ENVIRONMENTS.AMGEVITA;
    case DOMAINS.EPIDATA:
      return ENVIRONMENTS.EPIDATA;
    default:
      return ENVIRONMENTS.EPIDATA;
  }
})();

export const isEpiData = CURRENT_ENV === ENVIRONMENTS.EPIDATA;
export const isAmgevita = CURRENT_ENV === ENVIRONMENTS.AMGEVITA;
export const isKanjinti = CURRENT_ENV === ENVIRONMENTS.KANJINTI;


console.log(CURRENT_DOMAIN);
console.log(isEpiData);
console.log(isAmgevita);
console.log(isKanjinti);

export const LOGGED_FLAG = 'isLoggedIn';
