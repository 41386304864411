import { createTypes } from 'redux-recompose';

import { TARGETS, User } from './constants';

export const actions = createTypes(['SET_USER', 'CLEAR_USER'], ['@@IMPORTED_USER']);

export default {
  setUser: (user: User) => {
    localStorage.setItem('importedUser', JSON.stringify(user));
    return {
      type: actions.SET_USER,
      target: TARGETS.USER,
      payload: user
    };
  },
  clearUser: () => {
    localStorage.removeItem('importedUser');
    return {
      type: actions.CLEAR_USER,
      target: TARGETS.USER
    };
  }
};
