import i18next from 'i18next';

i18next.addResources('es', 'Detail', {
  potencialCasesByProfile: 'Casos elegibles por perfil del paciente',
  graphic: 'Grafico',
  logo: 'Logo de la marca',
  noPathologyDataFound: 'Error al cargar datos de la patologia.',
  previewModeDisclaimer:
    'Esta patología está en modo previsualización. Los datos están en proceso de revisión y/o actualización.'
});
