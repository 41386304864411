import i18next from 'i18next';

i18next.addResources('es', 'TotalSectionAmgevita', {
  title: 'AMGEVITA™',
  subtitle: 'Cuenta con la Totalidad de la Evidencia',
  titleEvidence1: 'Estudios Clínicos',
  msgEvidence1:
    'Eficacia y seguridad similar al PR en estudios FIII en artritis reumatoidea y psoriasis en placa¹ ²',
  titleEvidence2: 'Farmacología clínica',
  msgEvidence2: 'Farmacocinética, farmacodinamia e inmunogenicidad similar al PR⁷',
  titleEvidence3: 'Estudios Pre-Clínicos',
  msgEvidence3: 'Toxicocinética similar al PR⁶',
  titleEvidence4: 'Caracterización Analítica y Funcional',
  msgEvidence4: 'Similaridad con el PR en todos los atributos críticos de calidad evaluados⁴ ⁵',
  PR: 'PR: producto de referencia'
});
