import { Range } from '~components/PopulationWizard/PopulationWizardBasic/components/StepTwoBasic/constants';

export const TARGETS = {
  PYRAMID: 'pyramid',
  ESTIMATED_PYRAMID: 'estimatedPyramid',
  STATUS: 'status',
  ESTIMATED_DISTRIBUTION: 'estimatedDistribution',
  BASIC_DISTRIBUTION: 'basicDistribution',
  PYRAMIDS: 'pyramids'
};

export interface PyramidsState {
  pyramidLoading: boolean;
  pyramidError: boolean;
  pyramid: Pyramid;
  estimatedPyramid: Pyramid;
  estimatedPyramidLoading: boolean;
  estimatedPyramidError: boolean;
  estimatedDistribution: Pyramid;
  estimatedDistributionLoading: boolean;
  estimatedDistributionError: boolean;
  basicDistribution: Range[];
  pyramids: Pyramid[];
}

export interface Pyramid {
  id: number;
  createdAt: Date;
  groups: PyramidGroup[];
  name: string;
}

export interface PyramidGroup {
  from: number;
  to: number | null;
  male: number;
  female: number;
}
