import i18next from 'i18next';

i18next.addResources('es', 'PopulationList', {
  title: 'Mi población',
  noPopulations: 'No hay poblaciones',
  update: 'Modificar',
  delete: 'Eliminar',
  deletePopulation: '¿Querés eliminar esta población?',
  deleteDisclaimer: 'Si eliminas la población <strong>{{name}}</strong> no vas a poder recuperar los datos',
  viewAll: 'Ver todas',
  cancel: 'Cancelar',
  accept: 'Aceptar'
});
