import api from '../config/api';

interface SendSnapshot {
  snapshotName: string;
  id: number;
}

interface EditSnapshot {
  snapshotName: string;
  pathologyId: number;
  snapshotId: number;
}

interface Snapshot {
  pathologyId: number;
  snapshotId: number;
}

export const sendSnapshot = ({ snapshotName, id }: SendSnapshot) =>
  api.post(`/pathologies/${id}/snapshots`, {
    name: snapshotName
  });

export const getSnapshots = ({ id, page = 1 }: { id: number; page: number }) =>
  api.get(`/pathologies/${id}/snapshots?page=${page}&limit=10`);

export const editSnapshot = ({ snapshotName, pathologyId, snapshotId }: EditSnapshot) =>
  api.put(`/pathologies/${pathologyId}/snapshots/${snapshotId}`, {
    name: snapshotName
  });

export const deleteSnapshot = ({ pathologyId, snapshotId }: Snapshot) =>
  api.delete(`/pathologies/${pathologyId}/snapshots/${snapshotId}`);

export const updateSnapshot = ({ pathologyId, snapshotId }: Snapshot) =>
  api.put(`/pathologies/${pathologyId}/snapshots/update_selected`, {
    id: snapshotId
  });
