import { Snapshot } from '~redux/Snapshot/constants';

export interface PathologiesState {
  pathologies: {
    count: number;
    totalPages: number;
    totalCount: number;
    currentPage: number;
    nextPage: number;
    page: Pathology[];
  };
  pathology: Pathology;
  pathologiesError: boolean;
  pathologiesLoading: boolean;
  pathologyLoading: boolean;
  pathologyPatientFlows: PatientFlowElement[];
  pathologyPatientFlowLoading: boolean;
  pathologyPatientFlowError: boolean;
  pathologyStatics: PathologyStatics;
  pathologyStaticsLoading: boolean;
  pathologyStaticsError: boolean;
  editedPatientFlow: boolean;
  editedPatientFlowError: boolean;
  editedPatientFlowLoading: boolean;
}

export interface Pathology {
  id: number;
  incidence: string;
  name: string;
  potentialCases: number;
  prevalence: string;
  treatableCases: number;
  description: string;
  remedy: Remedy;
  patientFlow: PatientFlow[];
  displayStatus: 'active' | 'preview';
  selectedSnapshot: number;
  snapshots: Snapshot[];
  source: {
    name: string;
    description: string;
    url: string;
  };
}

interface Remedy {
  id: number;
  name: string;
  description: string;
}

export interface PathologyStatics {
  incidence: Statics;
  prevalence: Statics;
}

interface Statics {
  ageGroups: {
    from: number;
    to: number | null;
    total: number;
  }[];
  total: number;
  mocked: boolean;
}

export interface Scenario {
  default: boolean;
  id: number;
  name: string;
}

export interface Treatments {
  amgenCost?: number;
  comparativeCost?: number;
  customCost?: boolean;
  amgenCostDescription?: string[];
  comparativeCostDescription?: string[];
}

export interface PatientFlowElement {
  id: number;
  name?: string;
  initialAge: number;
  finalAge: number;
  potentialCases: number;
  treatableCases: number;
  flowCharacters: PatientFlow[];
  scenarios: Scenario[];
  selectedScenario: number;
  amgenTreatmentCostDescription: string[];
  comparativeTreatmentCostDescription: string[];
  treatments: Treatments;
}

export interface PatientFlow {
  id: number;
  editable: boolean;
  affected: number;
  affectedPopulationPercentage: number;
  description?: string;
  name: string;
  treatable: boolean;
  children: PatientFlow[];
  scenarios?: string[];
  source?: string;
  sourceUrl?: string;
  amgenCost?: number;
  comparatorCost?: number;
}

export const TARGETS = {
  PATHOLOGIES: 'pathologies',
  PATHOLOGY: 'pathology',
  PATHOLOGY_STATICS: 'pathologyStatics',
  EDITED_PATIENT_FLOW: 'editedPatientFlow',
  PATHOLOGY_PATIENT_FLOWS: 'pathologyPatientFlows'
};
