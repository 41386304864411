import i18next from 'i18next';

i18next.addResources('es', 'StepTwoBasic', {
  from: 'Desde',
  to: 'Hasta',
  male: 'Hombres',
  female: 'Mujeres',
  rangesError: 'Hay rangos incompletos o que se pisan',
  detail: 'Podés distribuir tus afiliados en rangos generales de edad de cada 5 años'
});
