import i18next from 'i18next';

i18next.addResources('es', 'PopulationWizard', {
  title: 'Carga de afiliados',
  total: 'Total de afiliados',
  distribution: 'Distribución',
  pyramid: 'Pirámide',
  affiliatesTitle: '¿Cuántos afiliados forman parte de tu población?',
  distributionTitle: 'Distribución de tus afiliados',
  affiliatesPlaceholder: 'Ej. 500.000.000',
  next: 'Siguiente',
  cancel: 'Cancelar',
  back: 'Volver',
  savePopulation: 'Guardar Poblacion',
  stepThreeSubtitle: 'Estimación basada en tus afiliados y la población general.',
  populationLeft: 'Te faltan distribuir ',
  completeRanges: 'Completá todos los rangos que puedas para obtener óptimos resultados.',
  distributed: '{{amount}}% Distribuido',
  distributionError:
    'Ya distribuiste el total de afiliados. Los afiliados que agregues se sumarán al total de tu población.',
  updateDistribution: '¿Querés actualizar el total de población?',
  distributionResume:
    'Cargaste un total de {{total}} afiliados en la primera instancia, y distribuiste {{totalDistributed}}.',
  updateTotal: 'Actualizar total' as string,
  alreadyDistributed: 'Ya distribuiste todos tus afiliados!',
  missingAffiliates: 'Te faltan afiliados por cargar',
  missingAffiliatesInfo:
    'Vamos a distribuir automáticamente los rangos faltantes. Para un informe más detallado y mejor previsibilidad, completá todos tus rangos de población.',
  continueWithoutLoad: 'Continuar sin cargar',
  loadMore: 'Cargar más rangos',
  ready: '¡Listo!',
  affiliatesLoaded: 'Tus afiliados se cargaron exitosamente',
  watchReports: 'Ver todos los reportes',
  basicDistributionTitle: 'Distribución básica',
  basicOrAdvancedModalTitle: '¿Cómo se distribuye tu población?',
  basicOrAdvancedModalText:
    'Podés distribuirla de forma Básica (rangos amplios) o de forma Avanzada (rangos específicos) para obtener un informe más detallado.',
  basicOrAdvancedModalBasic: 'Carga Básica',
  basicOrAdvancedModalAdvanced: 'Carga Avanzada',
  completeRangesCSV:
    'Cargá tu archivo y completá todos los rangos que puedas para obtener óptimos resultados.',
  importPopulationCSVTitle: 'Distribución de tus afiliados',
  updateTotalWarning: 'Los afiliados que agregues se sumarán al total de tu población.',
  fileLoaded: 'Tus archivo fue cargado exitosamente y los afiliados han sido importados',
  continue: 'Continuar',
  affiliatesName: '¿Cómo querés llamar esta población?',
  affiliatesNamePlaceholder: 'Mi Población',
  populationName: 'Nombre de la población',
  name: 'Nombre'
});
