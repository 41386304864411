import { LoginProps } from '~screens/Authentication/screens/Login/loginModel';
import { LogoutHeaders } from '~redux/Login/constants';

import api from '../config/api';

export const loginUser = (user: LoginProps) => api.post('/auth/sign_in', user);

export const logoutUser = (logOutHeaders: LogoutHeaders) =>
  api.delete('auth/sign_out', {}, { headers: logOutHeaders });

export const getUserInfo = (id: number) => api.get(`/users/${id}`);

export const getUserPopulation = () => api.get('/pyramids/active');

export const restorePopulation = () => api.delete('/users/destroy_pyramids');

export const recoverPassword = (email: string) => api.get('/users/password/restore', { email });

interface ParamsTypes {
  password: string;
  token: string;
}

export const setPassword = ({ password, token }: ParamsTypes) =>
  api.put(
    '/users/password',
    { password, passwordConfirmation: password },
    { headers: { 'reset-password-token': token } }
  );
