import { lazy } from 'react';

import { ENVIRONMENTS } from '~constants/environments';
import Routes from '~constants/routes';

const Kanjinti = lazy(() => import('~screens/Kanjinti'));
const EpidataRoutes = lazy(() => import('~screens/Dashboard'));
const AmgevitaRoutes = lazy(() => import('~screens/Amgevita'));

export const ROUTE_COMPONENTS = {
  [ENVIRONMENTS.AMGEVITA as string]: {
    path: Routes.LANDING_HOME,
    component: AmgevitaRoutes
  },
  [ENVIRONMENTS.EPIDATA as string]: {
    path: Routes.HOME,
    component: EpidataRoutes
  },
  [ENVIRONMENTS.KANJINTI as string]: {
    path: Routes.LANDING_HOME,
    component: Kanjinti
  }
};
