import i18next from 'i18next';

import { invalidCredentials } from '../Login/constants';

i18next.addResources('es', 'Recover', {
  title: '¿Olvidaste tu contraseña?',
  subtitle: 'Ingresá tu correo electrónico y te enviaremos un enlace para reestablecer tu contraseña.',
  send: 'Enviar',
  back: 'Volver',
  checkEmail: 'Revisá tu E-mail',
  checkEmailInfo:
    'Si el email ingresado es válido en breve recibirás un correo electrónico con los pasos a seguir para reestablecer tu contraseña.',
  [invalidCredentials]: 'Correo electrónico inválido. Volvé a intentarlo.'
});
