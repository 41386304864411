import { createTypes } from 'redux-recompose';

import { ModalIds } from './constants';

export const actions = createTypes(['OPEN_MODAL', 'CLOSE_MODAL', 'MODAL_ACTION'], '@@MODAL');

export const actionCreators = {
  openModal: (modalId: ModalIds, data?: any) => ({
    type: actions.OPEN_MODAL,
    payload: { modalId, ...(data && { data }) }
  }),
  closeModal: (modalId: ModalIds) => ({
    type: actions.CLOSE_MODAL,
    payload: { modalId }
  })
};
