import { Pyramid } from '~redux/Pyramid/constants';

export interface UserState {
  user: User;
}

export interface User extends Pyramid {
  user: {
    id: number;
    entity: string;
    firstName: string;
    lastName: string;
  };
  totalPopulation: number;
}

export const TARGETS = {
  USER: 'user'
};
