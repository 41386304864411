import { LoginProps } from '~screens/Authentication/screens/Login/loginModel';

export const FIELDS = {
  email: 'email',
  password: 'password',
  status: 'status'
};

export const INITIAL_VALUES: LoginProps = { email: '', password: '' };

export const invalidCredentials = 'CLIENT_ERROR';
export const rejected = 'rejected';
export const pending = 'pending';
export const tooManyRequests = 'tooManyRequests';
