export enum Modals {
  home = 'Home',
  addAffiliates = 'AddAffiliates',
  populationWizard = 'populationWizard',
  confirmDistribution = 'ConfirmDistribution',
  costAmgenModal = 'CostAmgenModal',
  costComparatorModal = 'CostComparatorModal',
  costCustomModal = 'CostCustomModal',
  infieldImport = 'InfieldImport',
  pyramid = 'Pyramid',
  simulationAlert = 'SimulationAlert',
  confirmMoreDistribution = 'ConfirmMoreDistribution',
  confirmLessDistribution = 'ConfirmLessDistribution',
  confirmFinishDistribution = 'ConfirmFinishDistribution',
  loadAffiliatesWarning = 'LoadAffiliatesWarning',
  introductionModal = 'IntroductionModal',
  chooseBasicOrAdvancedDistribution = 'chooseBasicOrAdvancedDistribution',
  populationWizardCSV = 'populationWizardCSV',
  csvUploaded = 'CSVUploaded',
  scenarioCreatorModal = 'ScenarioCreatorModal',
  scenarioAdminModal = 'ScenarioAdminModal',
  deleteScenarioModal = 'deleteScenarioModal',
  deletePopulationModal = 'deletePopulationModal',
  viewAllPopulationsModal = 'viewAllPopulationsModal'
}

export interface ModalState extends ModalNames {
  data?: any;
}

export type ModalNames = { [key in ModalIds]: boolean };

// TODO: add modalsIds here
export type ModalIds =
  | Modals.home
  | Modals.addAffiliates
  | Modals.populationWizard
  | Modals.confirmDistribution
  | Modals.costAmgenModal
  | Modals.costCustomModal
  | Modals.costComparatorModal
  | Modals.infieldImport
  | Modals.pyramid
  | Modals.simulationAlert
  | Modals.confirmMoreDistribution
  | Modals.confirmLessDistribution
  | Modals.confirmFinishDistribution
  | Modals.loadAffiliatesWarning
  | Modals.introductionModal
  | Modals.chooseBasicOrAdvancedDistribution
  | Modals.populationWizardCSV
  | Modals.csvUploaded
  | Modals.scenarioCreatorModal
  | Modals.scenarioAdminModal
  | Modals.deleteScenarioModal
  | Modals.deletePopulationModal
  | Modals.viewAllPopulationsModal;
