import i18next from 'i18next';

i18next.addResources('es', 'ClinicalEssays', {
  section1: 'Tipos de estudios clínicos en productos biológicos y biosimilares',
  section2: 'Estudio FIII en Artritis Reumatoidea (AR) moderada a severa',
  section3: 'Estudio FII en Psoariasis en Placa (PSO) moderada a severa',
  section2Subsection1: 'Diseño del Estudio (AR)',
  section2Subsection2: 'Criterios de Inclusión y Exclusion Criterios de Valoración (AR)',
  section2Subsection3: 'Características Basales de la Población (AR)',
  section2Subsection4: 'Resultados del Criterio de Valoración Primaria (AR)',
  section2Subsection5: 'Eficacia (AR)',
  section2Subsection6: 'Seguridad e Inmunogenicidad (AR)',
  section3Subsection1: 'Diseño del Estudio (PSO)',
  section3Subsection2: 'Criterios de Inclusión y Exclusion Criterios de Valoración (PSO)',
  section3Subsection3: 'Características Basales de la Población (PSO)',
  section3Subsection4: 'Resultados del Criterio de Valoración Primaria (PSO)',
  section3Subsection5: 'Eficacia (PSO)',
  section3Subsection6: 'Seguridad e Inmunogenicidad (PSO)',
  allSections: 'Ver todas las secciones'
});
