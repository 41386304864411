import i18next from 'i18next';

i18next.addResources('es', 'KanjintiHero', {
  title: 'Kanjinti 420 mg (IV)*',
  secondTitle: 'El primer biosimilar de trastuzumab en Argentina',
  descriptionTitle: 'Aprobado para el tratamiento de pacientes adultos con:',
  description1Bullet: 'Cáncer de mama temprano HER2+',
  description2Bullet: 'Cáncer de mama metastásico HER2+',
  description3Bullet: 'Cáncer gástrico metastásico HER2+',
  descriptionDisposition: 'DI-2019-8434-APN-ANMAT#MS',
  bottomDescriptionTitle: '1 vial de uso único por envase',
  anmatDisposition: 'DI-2019-8434-APN-ANMAT#MSYDS',
  efficacyAndSafety: 'Eficacia y seguridad: Evidencia clínica robusta',
  experience: '40 años de experiencia produciendo biotecnología con la más alta calidad',
  emaApproved: 'Aprobado por EMA'
});
