import i18next from 'i18next';

import actionCreators from '~redux/Login/actions';

import { serializer, deserializer } from './serializer';
import api from './api';

const STATUS_CODES = {
  unauthorized: 401
};

// eslint-disable-next-line no-unused-vars, prettier/prettier
export const apiSetup = (dispatch: any) => {
  api.addMonitor((response: any) => {
    if (response.status === STATUS_CODES.unauthorized) {
      dispatch(actionCreators.clearUser());
    }
  });

  api.addMonitor(response => {
    if (response.problem === 'NETWORK_ERROR') {
      // TODO: These callbacks should only be called if no other callback was asigned for the response.
    }
  });

  api.addResponseTransform((response: any) => {
    if (response.data) {
      response.data = deserializer.serialize(response.data);
    }
    if (!response.status) {
      response.problem = i18next.t(`RequestErrors:${response.problem}`);
    }
  });

  api.addRequestTransform(request => {
    if (request.data) {
      request.data = serializer.serialize(request.data);
    }
  });
};
